export const userMenuItems = [
  {
    role: "any",
    title: "Shipping Addresses",
    url: "/addresses",
    user: false,
  },
  {
    role: "any",
    title: "Cart",
    url: "/cart",
    user: false,
  },
  {
    role: "any",
    title: "Orders",
    url: "/orders",
    user: true,
  },
  {
    role: "admin",
    title: "Admin",
    url: "/admin",
    submenu: [
      {
        role: "admin",
        title: "View/Edit All Orders",
        url: "/admin/orders",
        user: false,
      },
      {
        role: "admin",
        title: "View/Edit Pending Orders",
        url: "/admin/orders/pending",
        user: false,
      },
      {
        role: "admin",
        title: "Discounts",
        url: "/admin/discounts",
        user: false,
      },
      {
        role: "admin",
        title: "Coupons",
        url: "/admin/coupons",
        user: false,
      },
      {
        role: "admin",
        title: "Listings",
        url: "/admin/listings",
        user: false,
      },
      {
        role: "admin",
        title: "Maintenance",
        url: "/admin/maintenance",
        user: false,
      },
      {
        role: "admin",
        title: "New Blogs",
        url: "/admin/updateblogs",
        user: false,
      },
      {
        role: "admin",
        title: "Test Model",
        url: "/admin/testmodel",
        user: false,
      },
    ]
  },
];





