export async function updateListing(listing) {  
  const result = await fetch(`/api/listings/${listing.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(listing)
  });

  return await result.json();
};

export async function updateProducts(listing) {  
  const result = await fetch("/api/products", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(listing.products)
  });

  return await result.json();
};

function toObject(styles) {
  var rv = {};
  for (const style of styles) {
    rv[style.category] = style.styles.value;
  }
  return rv;
}

function getMaxPrice(obj1, obj2, basePrice) {
  // if both objects have an override, combine the additional above the base price
  const combinedObject = {...obj1, ...obj2};
  const result = {};

  Object.keys(combinedObject).forEach(key => {
    if (key === "overridePrice") {
      const add1 = (obj1[key] || basePrice) - basePrice;
      const add2 = (obj2[key] || basePrice) - basePrice;
      if (add1 + add2 > 0) {
        result[key] = basePrice + add1 + add2;
      }
    } else {
      result[key] = combinedObject[key];
    }
  })
  return result;
}

function combineArraysCombinations({arrays, basePrice}) {
  const cleanedArrays = arrays.filter(subArray => subArray.length > 0);

  const result = [];

  const generateCombinations = (index, currentCombination) => {
    if (index === cleanedArrays.length) {
      result.push(currentCombination);
      return;
    }

    cleanedArrays[index].forEach((element) => {
      generateCombinations(index + 1, getMaxPrice(currentCombination, element, basePrice));
    });
  };

  generateCombinations(0, []);

  return result;
}

function removeUndefined(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

function foundAttributes(product, attributes) {
  const keys = Object.keys(product);
  let foundAttributes = attributes;
  for (const key of keys) {
    if (key !== "id" && key !== "overridePrice") {
      foundAttributes = foundAttributes.filter(object => object[key] === product[key]);
    }
  };
  if (foundAttributes.length > 0) {
    return {...product, ...foundAttributes[0]}
  }
}

function orphanedAttributes(product, attributes) {
  const keys = Object.keys(product);
  let foundAttributes = attributes;
  for (const key of keys) {
    if (key !== "id" && key !== "overridePrice") {
      foundAttributes = foundAttributes.filter(object => object[key] === product[key]);
    }
  };
  if (foundAttributes.length === 0) {
    return product  
  }
}

function newAttributes(attribute, products) {
  const keys = Object.keys(attribute);
  let foundProducts = products;
  for (const key of keys) {
    if (key !== "overridePrice") {
      console.log(attribute[key])
      foundProducts = foundProducts.filter(object => object.key === attribute[key]);
      if (attribute[key] === "Christmas Green") {
        console.log("Green:", foundProducts)
      }
    }
  };
  if (foundProducts.length === 0) {
    return true;
  }
  return false;
}

// create virtual products
export function createProductsFromListing(listing) { 
  const styleArrays = listing.styles.map(object => (object.styles.map(style => ({[object.category]: style.value, overridePrice: style.overridePrice})))); 
  const sizeArray = listing.sizes.map(size => ({size: size.size, overridePrice: size.overridePrice}));
  const colorArray = listing.colors.map(color => ({color: color.color, overridePrice: color.overridePrice}));
  const attributes = combineArraysCombinations({arrays: [...styleArrays, sizeArray, colorArray], basePrice: listing.price});
  let newProducts = [];

  for (const product of attributes) {
    let newProduct = {
      sku: "",
      listing: listing._id,
      price: product.overridePrice || listing.price,
      status: "active",
    }
    delete product.overridePrice;
    if (product.size) {
      newProduct = {...newProduct, size: {size: product.size, imageUrl: ""}}
      delete product.size;
    }
    if (product.color) {
      newProduct = {...newProduct, color: {color: product.color, imageUrl: ""}}
      delete product.color;
    }
    // create style array from remaining keys (if any)
    let remainingKeys = Object.keys(product)
    newProduct = {...newProduct, style: remainingKeys.map(key => ({category: key, styles: {value: product[key], imageUrl: ""}}))};
    newProducts.push(newProduct);
  }
  return newProducts;
}

// uses listing attributes
export async function updateProductsFromListing(listing) { 
  if (listing.products?.length > 0) {
    // create a flat array of all listing attribute combinations
    let flatProducts = listing.products.map(product => ({id: product._id, ...toObject(product.style), size: product.size?.size, color: product.color?.color}));
    flatProducts = flatProducts.map(product => removeUndefined(product));

    const styleArrays = listing.styles.map(object => (object.styles.map(style => ({[object.category]: style.value, overridePrice: style.overridePrice})))); 
    const sizeArray = listing.sizes.map(size => ({size: size.size, overridePrice: size.overridePrice}));
    const colorArray = listing.colors.map(color => ({color: color.color, overridePrice: color.overridePrice}));
    const flatAttributes = combineArraysCombinations({arrays: [...styleArrays, sizeArray, colorArray], basePrice: listing.price});

    // match products with attributes 
    const foundProducts = flatProducts.map(product => foundAttributes(product, flatAttributes)).filter(object => object);
    const orphanedProducts = flatProducts.map(product => orphanedAttributes(product, flatAttributes)).filter(object => object);
    const newProducts = flatAttributes.filter(attribute => newAttributes(attribute, flatProducts));

    // update found products
    let productsToUpdate = [];
    productsToUpdate = foundProducts.map(product => 
      ({_id: product.id, price: product.overridePrice || listing.price}));  
    // flag orphans for deletion
    for (const product of orphanedProducts) {
      productsToUpdate.push({_id: product.id, status: "delete"})
    }

    const result = await fetch("/api/products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(productsToUpdate)
    });

    // add new products
    if (newProducts.length > 0) {
      const ObjectID = require("bson-objectid");  

      for (let product of newProducts) {
        let newProduct = {
          _id: ObjectID().toString(),
          sku: "",
          listing: listing._id,
          price: product.overridePrice || listing.price,
          status: "active",
        }
        delete product.overridePrice;
        if (product.size) {
          newProduct = {...newProduct, size: {size: product.size, imageUrl: ""}}
          delete product.size;
        }
        if (product.color) {
          newProduct = {...newProduct, color: {color: product.color, imageUrl: ""}}
          delete product.color;
        }
        // create style array from remaining keys (if any)
        let remainingKeys = Object.keys(product)
        newProduct = {...newProduct, style: remainingKeys.map(key => ({category: key, styles: {value: product[key], imageUrl: ""}}))};
        const newResult = await fetch("/api/products", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify([newProduct])
        });
      }
    } 
    return result;
  }
};
