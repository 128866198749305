import { Button, Box, Card, Grid, Container, Menu, Typography } from "@mui/material";

import { Link, useLoaderData } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { MenuSub } from "../components/MenuSub";
import AutoPlayVideo from "../components/AutoPlayVideo";
import { useLoading } from "../components/LoadingContext";

const Home = () => {
  const [portrait, setPortrait] = useState(true);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const caminoNames = useLoaderData();  
  const caminosList = caminoNames.map(object => ({title: object.name, url: `/caminos/${object.name}`}));
  const { setLoading } = useLoading();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    function checkOrientation() {
      setPortrait( window.innerWidth < window.innerHeight ? true : false) ;
    }
    window.addEventListener("resize", checkOrientation);
    checkOrientation();
    setLoading(true);
    return () => window.removeEventListener("resize", checkOrientation);
  }, []);

  const beforeLoad = () => {
    console.log("before load")
  }


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (

    <Container disableGutters maxWidth="100%" sx={{maxheight:"100vh" }}>
      <Card >
      { portrait ? 
        <>
        <Box sx={{position: "relative", height: "50vh"}}>
          <LazyLoadComponent placeholder={<img src={"/images/pages/widehiking.jpg"} alt="hiking" />}>
            <AutoPlayVideo
                height="50vh" 
                url={"/images/pages/widehiking.mp4"}
                fit="cover"
              />             
          </LazyLoadComponent>
          <Typography 
            align="center"
            sx={{
              fontSize: {xs: 35, md: 45},
              fontWeight: "bold",
              position: "absolute", 
              color: "#ffffffff",
              top: "10vh",
              width: "90%",
              left: {xs: "50%", md: "25%"},
              transform: "translateX(-50%)",
            }}
          > 
            PLAN YOUR TRIP
          </Typography>
          <Button 
            component={Link}
            to="/plan"
            variant="contained"
            sx={{
              top: "20vh",
              position: "absolute", 
              marginLeft: "40%",
              background: "#fdd008",
              borderRadius: 30,
              width: 80,
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none"
            }}
          > 
            Plan
          </Button>
        </Box>
        <Box sx={{position: "relative", height: "50vh"}}>
        <LazyLoadComponent placeholder={<img src={"/images/pages/shop.jpg"} alt="shopping" />}>
        <AutoPlayVideo 
            height="50vh" 
            url={"/images/pages/shop.mp4"}
            fit="cover"
          />
          </LazyLoadComponent>
          <Typography 
            align="center"
            sx={{
              fontSize: {xs: 35, md: 45},
              fontWeight: "bold",
              position: "absolute", 
              color: "#ffffffff",
              top: "10vh",
              width: "90%",
              left: {xs: "50%", md: "75%"},
              transform: "translateX(-50%)",
            }}
          > 
            GET YOUR GEAR
          </Typography>
          <Button 
            variant="contained"
            component={Link}
            to={"/store"}
            sx={{
              top: "20vh",
              position: "absolute", 
              marginLeft: "40%",
              background: "#fdd008",
              borderRadius: 30,
              width: 80,
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "none"
            }}
          > 
            Shop
          </Button>
        </Box> 
        </>          
        :
        <Grid container>
          <Grid item xs={6} sx={{position: "relative"}}>
            <LazyLoadComponent beforeLoad={beforeLoad} placeholder={<img src={"/images/pages/widehiking.jpg"} alt="hiking" />}>
              <AutoPlayVideo
                height="80vh"
                url={"/images/pages/widehiking.mp4"}
                fit="cover"
              /> 
            </LazyLoadComponent>
            <Typography 
              align="center"
              sx={{
                fontSize: {xs: 35, md: 45},
                fontWeight: "bold",
                position: "absolute", 
                color: "#ffffffff",
                top: "30vh",
                width: "90%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            > 
              PLAN YOUR TRIP
            </Typography>
            <Button 
              variant="contained"
              component={Link}
              to="/plan"
              sx={{
                top: "40vh",
                position: "absolute", 
                background: "#fdd008",
                borderRadius: 30,
                fontSize: 16,
                fontWeight: "bold",
                left: "50%",
                transform: "translateX(-50%)",
                textTransform: "none"
              }}
            > 
              Plan
            </Button>
          </Grid>
          <Grid item xs={6} sx={{position: "relative"}}>
          <LazyLoadComponent placeholder={<img src={"/images/pages/shop.jpg"} alt="shopping" />}>
            <AutoPlayVideo 
              height="80vh"
              url={"/images/pages/shop.mp4"}
              fit="cover"
            />
          </LazyLoadComponent>
            <Typography 
              align="center"
              sx={{
                fontSize: {xs: 35, md: 45},
                fontWeight: "bold",
                position: "absolute", 
                color: "#ffffffff",
                top: "30vh",
                width: "90%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            > 
              GET YOUR GEAR
            </Typography>
            <Button 
              variant="contained"
              component={Link}
              to={"/store"}
              sx={{
                top: "40vh",
                position: "absolute", 
                background: "#fdd008",
                borderRadius: 30,
                fontSize: 16,
                fontWeight: "bold",
                left: "50%",
                transform: "translateX(-50%)",
                textTransform: "none"
              }}
            > 
              Shop
            </Button>
          </Grid>
        </Grid> 
      }
        <Menu
          id="caminos-menu"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block' },
          }}
        >
          {caminosList.map((item, index) => {                
            return (
            <MenuSub key={index} item={item} closeMenu={handleCloseNavMenu} />
            )
          })}
        </Menu>
      </Card>
    </Container>    
  )
};

export default Home;
