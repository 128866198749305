import { circularProgressClasses } from '@mui/material';
import GoogleMapReact from 'google-map-react';

import { useEffect, useState } from 'react';

// if kmlSource is an array of objects then load each kml as a layer with a unique id and add a click for each layer
// to jump to stage
export default function Map({
  noControls = false,
  style, 
  center, 
  zoom, 
  name, 
  kmlSource,
  handleKmlClick,
  handleMapClick, 
  markerLocation, 
  startMarker,
  endMarker,
  toggleRun, 
  stageMarkers, 
  openStage, 
  townMarkers,
  lodgingMarkers,
  townAccomodations, 
  openAccomodation,
  poiMarkers, 
  townPois,
  displayLodging,
  route,
  lodgingMode,
  getClick,
  }) {

  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);
  const [priorMarker, setMarker] = useState();
  let pois = [];
  let towns = [];
  let lodgings = [];

  const addClickListener = (map) => {
    map.addListener("click", (e) => {
      mapClick({lat: e.latLng.lat(), lng: e.latLng.lng()})
    })
  }

  const mapClick = (clickedLocation) => {
    handleMapClick(clickedLocation);
  }

  const townMarkerClick = (marker) => {
    townAccomodations(marker);
  }

  const lodgingMarkerClick = (marker) => {
    openAccomodation(marker);
  }


  // const poiMarkerClick = (marker) => {
  //   townPois(marker);
  // }

  const stageClick = (marker) => {
    openStage(marker);
  }

  const kmlClick = (kml) => {
    handleKmlClick(kml);
  }

  useEffect(() => {
    if (googleApiObj) {
      const { map, maps } = googleApiObj;
      renderMarker(map, maps);
    } 
  }, [googleApiObj, markerLocation])

  const renderMarker = (map, maps) => {
    if (markerLocation) {
      if (priorMarker) {
        priorMarker.setPosition({ lat: markerLocation.lat, lng: markerLocation.long });
      } else {
        let marker = new maps.Marker({
          map,
          position: { lat: markerLocation.lat, lng: markerLocation.long},
          optimized: false,
          label: {
            text: "\ue50a",
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "18px",
          },
          title: "Start",
        });  
        setMarker(marker);
        return marker;
      }  
    }
  }

  function pinSymbol(color) {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 2,
        scale: 1,
    };
  }

  const addStartMarker = (map, maps) => {
    if (startMarker?.lat !== 0) {
      const marker = new maps.Marker({
        position: startMarker,
        map,
        optimized: false,
        icon: pinSymbol("green"),
        title: "Start",
      });
      return marker;
    }
  }

  const addEndMarker = (map, maps) => {
    if (endMarker?.lat !== 0) {
      const marker = new maps.Marker({
        position: endMarker,
        icon: pinSymbol("red"),
        map,
        optimized: false,
        title: "End",
      });
      return marker;
    }
  }

  function addStageMarker(position, name, map, maps) {
    const marker = new maps.Marker({
      onclick: {stageClick},
      position,
      map,
      optimized: false,
      label: {
        text: "\ue50a",
        fontFamily: "Material Icons",
        color: "#ffffff",
        fontSize: "18px",
      },
      title: name,
    });
    marker.addListener("click", () => {
      stageClick(marker);
    })
    return marker;
  }

  const renderStageMarkers = (map, maps) => {
    if (stageMarkers) {
      clearMarkers();
      for (const stage of stageMarkers) {
        addStageMarker({lat: stage.lat, lng: stage.lng}, stage.name, map, maps).setMap(map);
      }  
    } else {
      clearMarkers();
    }
  }

  function addTownMarker(position, name, map, maps) {
    console.log("addTownMarker")
    const marker = new maps.Marker({
      onclick: {townMarkerClick},
      position,
      map,
      optimized: false,
      label: {
        text: "\uefdf",
        fontFamily: "Material Icons",
        color: "white",
        fontSize: "18px",
      },
      icon: pinSymbol("blue"),
      title: name,
    });
    marker.addListener("click", () => {
      townMarkerClick(marker);
    })
    towns.push(marker);
    return marker;
  }

  const renderTownMarkers = (map, maps) => {
    if (townMarkers && towns.length === 0) {
      clearMarkers();
      for (const town of townMarkers) {
        addTownMarker({lat: town.lat, lng: town.lng}, town.name, map, maps).setMap(map);
      }  
    } else {
      clearMarkers();
    }
  }

  function addLodgingMarker(position, name, map, maps) {
    const marker = new maps.Marker({
      onclick: {lodgingMarkerClick},
      position,
      map,
      optimized: false,
      label: {
        text: "\uefdf",
        fontFamily: "Material Icons",
        color: "white",
        fontSize: "18px",
      },
      icon: pinSymbol("red"),
      title: name,
    });
    marker.addListener("click", () => {
      lodgingMarkerClick(marker);
    })
    lodgings.push(marker);
    return marker;
  }

  const renderLodgingMarkers = (map, maps) => {
    if (lodgingMarkers && lodgings.length === 0) {
      clearMarkers();
      for (const lodging of lodgingMarkers) {
        addLodgingMarker({lat: lodging.lat, lng: lodging.lng}, lodging.name, map, maps).setMap(map);
      }  
    } 
  }

  // function addPoiMarker(position, name, map, maps) {
  //   const marker = new maps.Marker({
  //     onclick: {poiMarkerClick},
  //     position,
  //     map,
  //     optimized: false,
  //     icon: pinSymbol("gold"),
  //     title: name,
  //   });
  //   marker.addListener("click", () => {
  //     poiMarkerClick(marker);
  //   })
  //   pois.push(marker);
  //   return marker;
  // }

  // const renderPoiMarkers = (map, maps) => {
  //   if (poiMarkers && pois.length === 0) {
  //     clearMarkers();
  //     for (const town of poiMarkers) {
  //       addPoiMarker({lat: town.lat, lng: town.lng}, town.name, map, maps).setMap(map);
  //     }  
  //   } else {
  //     clearMarkers();
  //   }
  // }

  const clearMarkers = (map, maps) => {
    for (const marker of pois) {
      marker?.setMap(null);
    }
    for (const marker of towns) {
      marker?.setMap(null);
    }
    pois = [];
    towns = [];
  }

  function addOneKml(map, maps, name, kmlUri) {
    const kml = new maps.KmlLayer(kmlUri, {
      suppressInfoWindows: true,
      preserveViewport: true,
      map: map,
      name: name,
      clickable: Boolean(handleKmlClick)
    });
    if (Boolean(handleKmlClick)) {
      kml.addListener("click", () => {
        kmlClick(kml.name);
      })  
    } 
    return kml;
  }

  const renderKml = (map, maps) => {
    for (let i = 0; i < kmlSource.length; i++) {
      addOneKml(map, maps, kmlSource[i].name, kmlSource[i].kmlUri);
    }
  }

  const addOnePolyline = (map, maps, route) => {
    const polyline = new maps.Polyline({
      map: map,
      path: route,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    return polyline;
  }

  const renderPolyline = (map, maps) => {
    addOnePolyline(map, maps, route);
  }

  const callToggleRun = (event) => {
    toggleRun?.();
  }

  // Buttons to toggle display of markers
  function createLodgingControl(map, maps) {
    const controlButton = document.createElement("button");
  
    // Set CSS for the control.
    controlButton.style.backgroundColor = "blue";
    controlButton.style.color = "white";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontSize = "16px";
    controlButton.style.margin = "8px 0 0 8px";
    controlButton.style.textAlign = "center";
    controlButton.textContent = "Lodging";
    controlButton.title = "Click to display lodging markers";
    controlButton.type = "button";
    controlButton.addEventListener("click", () => {
      renderTownMarkers(map, maps);
    });
    return controlButton;
  }

  const renderLodgingButton = (map, maps) => {
    if (townMarkers) {
      const lodgingDiv = document.createElement("div");
      const lodgingControl = createLodgingControl(map, maps);
      lodgingDiv.appendChild(lodgingControl);
      map.controls[maps.ControlPosition.TOP_LEFT].push(lodgingDiv);  
    }
  }

  // function createPoiControl(map, maps) {
  //   const controlButton = document.createElement("button");
  
  //   // Set CSS for the control.
  //   controlButton.style.backgroundColor = "gold";
  //   controlButton.style.color = "black";
  //   controlButton.style.cursor = "pointer";
  //   controlButton.style.fontSize = "16px";
  //   controlButton.style.margin = "8px 0 0 8px";
  //   controlButton.style.textAlign = "center";
  //   controlButton.textContent = "Points of Interest";
  //   controlButton.title = "Click to display POI markers";
  //   controlButton.type = "button";
  //   controlButton.addEventListener("click", () => {
  //     renderPoiMarkers(map, maps);
  //   });
  //   return controlButton;
  // }

  // const renderPoiButton = (map, maps) => {
  //   if (poiMarkers) {
  //     const poiDiv = document.createElement("div");
  //     const poiControl = createPoiControl(map, maps);
  //     poiDiv.appendChild(poiControl);
  //     map.controls[maps.ControlPosition.TOP_LEFT].push(poiDiv);  
  //   }
  // }

  return (
    <div style={style} >
      <GoogleMapReact      
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
        center={center}
        zoom={zoom}
        onClick={callToggleRun}
        onGoogleApiLoaded = {
          ({ map, maps }) => {
          map.setOptions({
            disableDoubleClickZoom: true, 
            gestureHandling: noControls ? "none" : "greedy",
            zoomControl: !noControls,
            styles: [{
              featureType: 'all'
            }]
          })
          renderKml(map, maps)
          renderPolyline(map, maps)
          renderStageMarkers(map, maps)
          if (displayLodging) {renderTownMarkers(map, maps)}
          renderLodgingMarkers(map, maps)
          if (!lodgingMode) {renderLodgingButton(map, maps)}
          if (getClick) {addClickListener(map)}
          addStartMarker(map, maps)
          addEndMarker(map, maps)
          setIsGoogleApiLoadedObj(
            { map, maps })
          }          
        }
      >
      </GoogleMapReact>
    </div>
  )

}
