import { useAuth0 } from "@auth0/auth0-react";

import { Button, IconButton } from "@mui/material";

import LoginIcon from '@mui/icons-material/Login';

const LoginButton = ({cart, portrait}) => {
  const { loginWithRedirect } = useAuth0();
  const width = window.innerWidth;

  return (
    cart  && width < 900 ?
      <IconButton 
        onClick={() => loginWithRedirect({appState: { target: '/' }})}
        sx={{ width: 30, padding: 0, }}
      >
        <LoginIcon/>
      </IconButton>
    :
      <Button
        sx={{color: "black", fontSize: {xs: 15, md: 18}, fontWeight: "bold", padding: 0, textTransform: "none", "&:hover": {color: "#fdd008"}, }}
        onClick={() => loginWithRedirect({appState: { target: '/' }})}
      >
        Log In
      </Button>
  );
};

export default LoginButton;

/* You can pass a configuration object to loginWithRedirect() to customize the login experience. 
For example, you can pass options to redirect users to an Auth0 Universal Login page optimized for signing up 
for your React application. See RedirectLoginOptions for more details on these options.*/
