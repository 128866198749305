// Update store from Etsy API
import { useState } from 'react';

import { useLoaderData } from "react-router-dom";

import { DataGrid, GridRowModes,  GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, useGridApiRef } from "@mui/x-data-grid";

import { Button, Box, Grid, IconButton, } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { updateCoupon, deleteCoupon, newCouponObject, copyCoupon } from '../models/coupon';
import ChooseCategory from '../components/ChooseCategory';
import { useAlertContext } from '../components/AlertContext';

export async function couponLoader() {
  let response = await fetch("/api/coupons/all");
  let coupons = await response.json();
  for (let di = 0; di < coupons.length; di++) {
    coupons[di].id = coupons[di]._id;
    coupons[di].startDate = new Date(coupons[di].startDate);
    coupons[di].endDate = new Date(coupons[di].endDate);
  }
  response = await fetch("/api/store/categories/all");
  let categories = await response.json();
  return { coupons, categories };                  
};

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const newObject = newCouponObject();
    setRows((oldRows) => [...oldRows, newObject]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newObject.id]: { mode: GridRowModes.Edit, fieldToFocus: 'code' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function Coupon() {
  const { coupons, categories } = useLoaderData();
  const [rows, setRows] = useState(coupons);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openItems, setOpenItems] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState();
  const apiRef = useGridApiRef();
  const alertContext = useAlertContext();

  const handleItemsOpen = () => {
    setOpenItems(true);
  };

  const handleItemsClose = () => {
    setOpenItems(false);
  };

  const handleItemSelect = (selectedItems) => {
    let id;
    if (currentCoupon) {
      id = currentCoupon.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    apiRef.current.updateRows([{ id: id, items: selectedItems }]);
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCopyClick = (copyId) => async () => {
    let coupon = rows.find((coupon) => coupon.id === copyId);
    const copyRow = await copyCoupon(coupon);
    setRows((oldRows) => [...oldRows, copyRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [copyRow.id]: { mode: GridRowModes.Edit, fieldToFocus: 'sourceQty' },
    }));
  };

  const handleDeleteClick = (id) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    await deleteCoupon(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const verifyDates = async (coupon) => {
    if (coupon.startDate > coupon.endDate) {
      await alertContext.showConfirmation(
        {title: "Ending date must be later than or equal to starting date.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (coupon.endDate < new Date().setHours(0,0,0,0)) {
      await alertContext.showConfirmation(
        {title: "Ending date must be later than or equal to today.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }
  
  const verifyCart = async (coupon) => {
    if (coupon.cart && (coupon.any || coupon.items.length > 0)) {
      await alertContext.showConfirmation(
        {title: "If 'cart' is selected then do not select 'any' nor select any qualifying items.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyAny = async (coupon) => {
    if (coupon.any && coupon.items.length > 0) {
      await alertContext.showConfirmation(
        {title: "If 'any' is selected then do not select any qualifying items.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyMinimums = async (coupon) => {
    if (coupon.minQty && coupon.minAmount) {
      await alertContext.showConfirmation(
        {title: "Choose either a minimum quantity (units) or a minimum amount (value)",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyDiscounts = async (coupon) => {
    if (coupon.discountAmount && coupon.discountPercent) {
      await alertContext.showConfirmation(
        {title: "Choose either a discount amount or percent",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (coupon.discountLimitQty && coupon.discountLimitAmount) {
      await alertContext.showConfirmation(
        {title: "Choose either a quantity limit or an amount limit for the discount",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (coupon.discountPercent < 0 || coupon.discountPercent > 100) {
      await alertContext.showConfirmation(
        {title: "Discount % must be between 0 and 100",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const processRowUpdate = async (newRow, oldRow) => {
    var verified = false;
    verified = verifyDates(newRow);
    verified = verified && verifyCart(newRow);
    verified = verified && verifyAny(newRow);
    verified = verified && verifyMinimums(newRow);
    verified = verified && verifyDiscounts(newRow);
    if (verified) {
      newRow._id = newRow.id;
      console.log(newRow)
      await updateCoupon(newRow);  
      return newRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const handleItems = (coupon_id) => {
    setCurrentCoupon(coupons.find(coupon => coupon.id === coupon_id));
    setOpenItems(true);
  }

  const columns = [
    { field: 'code', headerName: "Code", editable: true, width: 100 }, 
    { field: 'name', headerName: "Name", editable: true, width: 100 }, 
    { field: 'description', headerName: "Description", editable: true, width: 250 },
    { field: 'startDate', headerName: "Start Date", editable: true, width: 90, type: "date",  },
    { field: 'endDate', headerName: "End Date", editable: true, width: 90, type: "date" },
    { field: 'unique', headerName: "Use one time?", editable: true, width: 50, type: "boolean" },
    { field: 'cart', headerName: "Cart?", editable: true, width: 50, type: "boolean" },
    { field: 'any', headerName: "Any?", editable: true, width: 50, type:"boolean" },
    { field: 'minSelf', headerName: "Minimum applies to each item", editable: true, width: 100, type:"boolean"},
    { field: 'minQty', headerName: "Minimum Qty", editable: true, width: 70, type: "number"},
    { field: 'minAmount', headerName: "Minimum Amount", editable: true, width: 70, type: "number" },
    { field: 'discountAmount', headerName: "Discount Amt", editable: true, width: 70, type: "number" },
    { field: 'discountPercent', headerName: "Discount %", editable: true, width: 70, type: "number" },
    { field: 'discountLimitQty', headerName: "Discount Limit Units", editable: true, width: 70, type: "number" },
    { field: 'disoucntLimitAmount', headerName: "Discount Limit Value", editable: true, width: 70, type: "number" },
    { field: 'items', headerName: "Qualifying Items", width: 100,
      renderCell: (params) => 
        <IconButton 
          style={{color: "inherit"}} 
          onClick={() => handleItems(params.row.id)}>
            <AccountTreeIcon  />
        </IconButton>,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={<ContentCopyIcon />}
          label="Copy"
          className="textPrimary"
          onClick={handleCopyClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]
 
  return(
    <Box >
      <Grid container sx={{mt:2, ml:4}} alignItems="flex-start" justify="flex-start" direction="column" spacing={2}>  
        <ChooseCategory
          categories={categories} 
          open={openItems} 
          handleClose={handleItemsClose} 
          handleOpen={handleItemsOpen}
          discount={currentCoupon}
          submitSelect={handleItemSelect}
        />  
        <h1>Coupons Maintenance</h1>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{
            '.MuiDataGrid-columnHeader': {color: "blue"},
            "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          }
        }}
          getRowHeight={() => "auto"}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Grid>
    </Box>
  )

};

export default Coupon;
