import { countries } from "./countries";

function totalCost(priceBreaks, quantity) {
  let cost = 0;
  const lastBreak = priceBreaks.length - 1
  for (let i = 0; i <= lastBreak; i++) {
    if (i < lastBreak) {
      cost += Math.min(quantity, priceBreaks[i + 1].breakQty - priceBreaks[i].breakQty) * priceBreaks[i].amount;
    } else {
      cost += ((quantity - priceBreaks[i].breakQty) + 1) * priceBreaks[i].amount;
    }
  }
  return cost;
}

export function calcShipping({countryIso, listing, quantity, rate, processingDate}) {
  const to = countries.find(country => country.iso === countryIso);
  const from = countries.find(country => country.iso === listing.originIso);
  const region = to?.region === "Europe" ? "eu" : "none";
  const toCountry = to?.name;
  const fromCountry = from?.name;
  const bestShipping = 
    listing.shippingDestinations.find(object => object.iso === countryIso) || 
    listing.shippingDestinations.find(object => object.region === region) ||
    listing.shippingDestinations.find(object => object.region === "none" && object.iso === "");

  // find total price based on quantity and breaks
  const shippingPrice = Number(totalCost(bestShipping.priceBreaks, quantity).toFixed(2));
  
  const today = new Date();
  let startDate = new Date(processingDate) || today;

  if (listing.etsyPartners?.length > 0) {
    startDate = today;
  }

  const minProcessingDays = Math.floor((Math.max(today, startDate) - today) / (24 * 60 * 60 * 1000)) + listing.processingMin; 
  const maxProcessingDays = Math.floor((Math.max(today, startDate) - today) / (24 * 60 * 60 * 1000)) + listing.processingMax; 

  const minTime = minProcessingDays + bestShipping.minDays;
  const maxTime = maxProcessingDays + bestShipping.maxDays;  

  return { region, toCountry, fromCountry, shippingPrice, minTime, maxTime };

};


