import {useState} from 'react';

import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function GlobalPrice({open, handleClose, submitGlobal}) {
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleSubmit = () => {
    submitGlobal(value)
  }

  return (
    <Dialog open={open} onClose={handleClose} >
    <DialogTitle>Enter a positive or negative percentage to adjust all product prices.</DialogTitle>
    <DialogContent align="center">
      <TextField
        autoFocus
        value={value}
        type="number"
        variant="standard"
        sx={{width: 50}}
        onChange={handleChange}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleSubmit}>Submit</Button>
    </DialogActions>
    </Dialog>  
    )
    
}

export default GlobalPrice;

