import {useRef, useEffect, memo} from "react"

import { CardMedia } from "@mui/material";

import { useLoading } from "./LoadingContext";

function AutoPlayVideo({ mt, height, url, fit = "cover" }) {
    const videoRef = useRef(null);
    const { setLoading } = useLoading();

    const loaded = () => {
      setLoading(false);
    }

    useEffect(() => {
        videoRef.current.defaultMuted = true;
        videoRef.current.muted = true;
    })

    return (
      <CardMedia
        ref={videoRef}                
        component="video"
        autoPlay
        loop
        playsInline
        type="video/mp4"
        sx={{
          mt: (mt ? mt : null),
          height: (height ? height : null),
          display: 'block',
          width: "100%",
          objectFit: fit,
          onLoad: () => loaded()                 
        }}
        image={url}
        alt="hold"
      />
    );
}

export default memo(AutoPlayVideo);
