export function newDiscountObject() {
  const ObjectID = require("bson-objectid");  
  const id = ObjectID().toString();
  return (
      { id,
      name: '', 
      description: '', 
      startDate: new Date(),
      endDate: new Date(),
      cart: false,
      any: false,
      items: [],
      minSelf: false,
      minQty: 0,
      minAmount: 0,
      discountAmount: 0,
      discountPercent: 0,
      discountLimitQty: 0,
      discountLimitAmount: 0,
      isNew: true 
    }
  )
};

export async function updateDiscount(discount) {  
  const result = await fetch(`/api/discounts/${discount.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(discount)
  });

  return await result.json();
};


export async function copyDiscount(discount) {
  const ObjectID = require("bson-objectid");  
  const id = ObjectID().toString();
  let newDiscount = structuredClone(discount);
  newDiscount.id = id;
  newDiscount.isNew = true;
  return newDiscount;
};

export async function deleteDiscount(id) {
  try {
    await fetch(`/api/discounts/delete/${id}`);
  }
  catch(err) {
    console.log(err);
  }
};
