import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

export const lightTheme =
  createTheme({
    typography: {
      fontFamily: "Josefin Sans",
      fontSize: 12
    },
    palette: {
      primary: {
        main: orange[500]
      },
      neutral: {
        main: "#000000",
      }
    },
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            //thumb - unchecked
            color: "green"
          },
          colorPrimary: {
            "&.Mui-checked": {
              // thumb - checked
              color: "red"
            }
          },
          track: {
            // track - unchecked
            opacity: 0.5,
            backgroundColor: "green",
            ".Mui-checked.Mui-checked + &": {
              // track - checked
              opacity: 0.5,
              backgroundColor: "red"
            }
          }
        }
      }
    }
  })


