import { Button, Card, CardMedia, Grid, Container, Typography } from "@mui/material";

import { Link } from 'react-router-dom';

const Plan = () => {

  return (

    <Container disableGutters maxWidth="100%" sx={{minHeight:"90vh" }}>
        <Typography align="center" padding={2} fontSize={{xs: 35, md: 45}} fontWeight="bold" height={80}  >
          Plan Your Trip
        </Typography>
      <Grid container justifyContent="space-around" >
        <Grid item xs={12} sm={5} sx={{height: "50vh", position: "relative"}}>
          <Card 
            component={Link} 
            to={"/caminos"}
          >
            <CardMedia
                component="img"
                alt={"Caminos"}
                image={"../images/june.png"}
                height = "100%"
                sx={{objectFit: "cover"}}
              />
            <Button 
              component={Link}
              to="/caminos"
              variant="contained"
              sx={{
                top: "25vh",
                position: "absolute", 
                left: {xs: "50%", md: "50%"},
                transform: "translateX(-50%)",
                background: "#fdd008",
                borderRadius: 30,
                width: 150,
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none"
              }}
            > 
              The Caminos
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} sx={{height: "50vh", position: "relative"}}>
          <Card 
            component={Link} 
            to={"/lodging"}
          >
            <CardMedia
                component="img"
                alt={"Lodging"}
                image={"../images/albergue.jpg"}
                sx={{objectFit: "cover"}}
                height = "100%"
              />
            <Button 
              component={Link}
              to="/lodging"
              variant="contained"
              sx={{
                top: "25vh",
                position: "absolute", 
                left: {xs: "50%", md: "50%"},
                transform: "translateX(-50%)",
                background: "#fdd008",
                borderRadius: 30,
                width: 150,
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none"
              }}
            > 
              Lodging
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} sx={{height: "50vh", position: "relative"}}>
          <Card 
            component={Link} 
            to={"/Services"}
          >
            <CardMedia
                component="img"
                alt={"Services"}
                image={"../images/luggage.jpg"}
                sx={{objectFit: "cover"}}
                height = "100%"
              />
            <Button 
              component={Link}
              to="/services"
              variant="contained"
              sx={{
                top: "25vh",
                position: "absolute", 
                left: {xs: "50%", md: "50%"},
                transform: "translateX(-50%)",
                background: "#fdd008",
                borderRadius: 30,
                width: 150,
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none"
              }}
            > 
              Services
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} sx={{height: "50vh", position: "relative"}}>
          <Card 
            component={Link} 
            to={"/tips"}
          >
            <CardMedia
                component="img"
                alt={"Tips"}
                image={"../images/question.jpg"}
                sx={{objectFit: "cover"}}
                height = "100%"
              />
            <Button 
              component={Link}
              to="/tips"
              variant="contained"
              sx={{
                top: "25vh",
                position: "absolute", 
                left: {xs: "50%", md: "50%"},
                transform: "translateX(-50%)",
                background: "#fdd008",
                borderRadius: 30,
                width: 150,
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none"
              }}
            > 
              Tips
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} sx={{height: "50vh", position: "relative"}}>
          <Card 
            component={Link} 
            to={"/tools"}
          >
            <CardMedia
                component="img"
                alt={"Tools"}
                image={"../images/chart.jpg"}
                sx={{objectFit: "cover"}}
                height = "100%"
              />
            <Button 
              component={Link}
              to="/tools"
              variant="contained"
              sx={{
                top: "25vh",
                position: "absolute", 
                left: {xs: "50%", md: "50%"},
                transform: "translateX(-50%)",
                background: "#fdd008",
                borderRadius: 30,
                width: 150,
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none"
              }}
            > 
              Tools
            </Button>
          </Card>
        </Grid>
     </Grid>
    </Container>    
  )
};

export default Plan;
