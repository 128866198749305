import { Box, Typography } from '@mui/material';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function ErrorPage({ message = "Sorry, but we can't find this page!"}) {

  return(
    <Box 
      height="100vh" 
      width="100vw"
    >
    <Navbar />
    <Typography variant="h3" align="center" mt={20} mb={30} >
      {message}
    </Typography>
    <Footer />
    </Box>        
   )

};

export default ErrorPage;
