import { useState } from "react";

import { useLoaderData, useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Box, Button, Card, CardHeader, Menu, CardContent, Grid, IconButton, Typography } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import { getUser } from "../models/user/userCartSlice";
import { MenuSub } from "../components/MenuSub";

export async function blogLoader() {
  try {
    let response = await fetch("/api/blogs");
    const blogData = await response.json();
    response = await fetch("/api/blogcategories");
    const categories = await response.json();
    return { blogData, categories };
  } catch (err) {
    // handle error
  }
}

function Blog() {
  const { blogData, categories } = useLoaderData();
  const navigate = useNavigate();
  const currentUser = useSelector(getUser)
  const [anchorElNav, setAnchorElNav] = useState(null);

  const categoryList = categories.map(object => 
    ({title: object.title, 
      submenu: object.blogs.map(blog => 
        ({title: blog.title, url: `/blogs/${blog.date}`}))
    })
  ); 

  const couponPattern = new RegExp(/<div class="coupon"(.*)<\/div>/, "gs");
  const namePattern = new RegExp(/{(.*)}/, "g");
  const linkPattern = new RegExp(/<a.*?>/, "gs");
  
  let blogs = blogData.map(blog => ({ post: blog.post.replace(/max-width: 600px;/g, ""), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(/width: 600px;/g, ""), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(/width="600"/g, ""), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(/font-size: 12px;/g, "font-size: 14px;"), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(/margin: 0px;/g, "margin-bottom: 10px;"), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(couponPattern, ""), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(linkPattern, ""), title: blog.title, date: blog.date}));
  blogs = blogs.map(blog => ({ post: blog.post.replace(namePattern, ""), title: blog.title.replace(namePattern, ""), date: blog.date}));
  blogs.sort((a,b) => (a.date > b.date) ? -1 : ((b.date > a.date) ? 1 : 0))
  
  const handleExpandClick = (e) => {
    console.log(`/blogs/${e.target.id}`)
    navigate(`/blogs/${e.target.id}`)
  };
  
  const handleEditClick = (e) => {
     navigate(`/blogs/edit/${e.target.id}`)
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  
  return (
    <>
    <Box width="100%" height="4vh">
      <IconButton
        sx={{
          position: 'fixed',
          mt: {xs: 1, md: 2},
          ml: {xs: 0, md: 5}, 
          width: 50, 
          transform: "scale(1.5)"}}
        size="large"
        aria-label="menu-appbar"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        {categoryList.map((item, index) => (
          <MenuSub key={index} item={item} closeMenu={handleCloseNavMenu} />
        ))}
      </Menu>
      </Box>
      <Typography fontSize={{xs: 30, md: 40}} align="center" mt={-2}>Sylvie's Blog</Typography> 
      <Box sx={{marginLeft: {xs: 0, lg: 5}}}>
      { blogs.map((blog, index) => (
        <Card key={index} sx={{ width: { xs: "100vw", lg: "90vw" }, height: {xs: "90vh", md: "60vh"}, mt: 2 }}>
          <CardHeader
            action={
              <>
              <IconButton
                sx={{mr: {lg: 10}, background: "#0bcaf0", color: "black"}}
                id={blog.date}
                size="small"
                onClick={handleExpandClick}
                aria-label="show more"
              >
                Read More
              </IconButton> 
              { currentUser.role === 'admin' ? 
              <Button
                id={blog.date}
                size="small"
                onClick={handleEditClick}
                aria-label="show more"
              >
                Edit
              </Button> 
              : null }
              </>
            }
            title={blog.title}
            subheader={blog.date ? blog.date.slice(0, 10) : ""}
          />
          <CardContent>
            <Grid container item alignItems="center" justifyContent="center" >
              <div
                dangerouslySetInnerHTML={{__html: blog.post}}
              >
              </div>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
    </>
  );
}  

export default Blog;