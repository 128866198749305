import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip6 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Where to Sleep
      </Typography>
      <Paper elevation={5} sx={{width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <span style={{fontSize: 24}}>W</span>hen you've walked all day, a comfy place to rest is all you need. But what type of Camino de Santiago accommodation suits you best?
          <br></br><br></br>The variety of accommodation options can enhance your Camino experience. Each offers unique features and amenities, catering to different comfort levels and budgets.
          <br></br><br></br>At the basic end, albergues provide dormitory-style rooms, fostering a communal atmosphere for an authentic Camino experience.
          <br></br><br></br>For more privacy and comfort, guesthouses and small hotels offer private rooms with en-suite bathrooms and personalized service.
          <br></br><br></br>Check out the Lodging tab on our website. Choose a camino and a stage and we'll display a map of the route along with all the towns along the way that offer accomodations. For each town we provide a listing of all alburges as well as linking to Booking.com for hostels, hotels, and guest houses.
          <br></br><br></br><span style={{fontSize: 24}}>A</span>lbergues/Hostels
          <br></br>A network of albergues (hostels) lines the Camino de Santiago, particularly on popular routes like the French Way. Public albergues, run by volunteers, allocate beds on a first-come-first-served basis. Private hostels with similar setups are also found in some towns, especially along the Camino Frances and Camino Portugues.
          <br></br><br></br><span style={{fontSize: 24}}>P</span>ensiones/Guesthouses
          <br></br>Guesthouses, also called 'pensiones' or 'hostales,' are small family-run businesses. These are good options offering private rooms with bathrooms, and many include dinner.
          <br></br><br></br><span style={{fontSize: 24}}>H</span>otels
          <br></br>In larger towns, you'll find hotels of all styles, ranging from 3 to 5 stars. Hotels provide private rooms and bathrooms for added comfort.
          <br></br><br></br><span style={{fontSize: 24}}>S</span>uperior Hotels and Paradores
          <br></br>For a luxurious experience, 4- and 5-star hotels, as well as Paradors, offer top-notch amenities and services. Paradors, located in historic buildings like castles or monasteries, blend comfort with history.
          <br></br><br></br><span style={{fontSize: 24}}>C</span>asa Rurales/Country Houses
          <br></br>'Casa rurales' or farm stay hotels, situated in rural areas, offer superior comfort in a rustic setting, providing a connection with the region's natural beauty.
          <br></br><br></br><span style={{fontSize: 24}}>B</span>ear in Mind
          <br></br>Bigger towns offer a better choice of accommodations. The Camino is a long-distance trail, so the mix of styles adds to your journey. Booking in advance, especially for the Camino Frances, is advisable.
          Enjoy your Camino journey! 🌟🚶‍♂️
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip6;