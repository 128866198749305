

export const menuItems = async() => ([
  { title: "Plan",
    submenu: [
      {title: "The Caminos", url: "/caminos"},
      {title: "Lodging", url: "/lodging"},
      {title: "Services", url: "/services"},
      {title: "Tips", url: "/tips"},
      {title: "Tools", url: "/tools"},
    ]
  },
  {
    title: "Shop",
    url: "/store",
  },
  {    
    title: "My Camino3D",
    submenu: [
      {title: "About Us", url: "/about"},
      {title: "Sylvie's Blog", url: "/blogs"},
      {title: "Contact Us", url: "mailto:admin@mycamino3d.com", email: true},
    ]
  },
 
]);





