import { Box, Typography, Grid, CardMedia } from '@mui/material'


const About = () => {
  return (
    <Box >
    <Typography mt={3} ml={{xs: 2, md: 5}} sx={{fontSize: {xs: 24, lg: 30}, fontWeight: 700}}>About Sylvie</Typography> 
      <Grid container direction="row" spacing={{xs: 1, md: 5}} padding={{xs: 2, md: 5}}  > 
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            alt="sly"
            src="/images/pages/Sylviecamino.jpg"
          />
        </Grid>
        <Grid item xs={12} md={5}>
        <Typography sx={{fontSize: {xs: 18, lg: 20}, fontWeight: "bold", textAlign: "justify"}}>
          Sylvie is an adventurer, she loves to explore, expand and enjoy life to its fullest. To be around Sylvie AKA Sly is to know and live life.
          <br></br><br></br>Sly is a French Spaniard, meaning she is Spanish by descent but was raised in Paris, spending summers in Spain. She spent time in the US getting her MBA and working in the US for 20+ years.
          <br></br><br></br>She has walked the Camino Del Santiago for the last 10 years, excluding Covid years.
          <br></br><br></br>She has walked the French Way and is currently finishing the Northern route. Her initial desire to walk the Camino was for her father who passed away before being able to walk the pilgrimage himself.
          <br></br><br></br>She has been a painter for many years and decided to take those skills combined with 5 years working on her 3D printing skills to make accessories for people walking the Camino. These products can be updated annually as you continue your journeys and track your progress.
          <br></br><br></br>She is a wealth of information regarding the Camino, and is available by email if you have questions to help you plan your trip…reach out to her.

</Typography>
        </Grid>
      </Grid>
  </Box>

  )
};

export default About;


