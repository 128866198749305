import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Grid, IconButton, Tooltip } from "@mui/material";

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import LoginButton from "./login-button";
import User from "./User";
import { fetchUser, createGuest } from "../models/user/userCartSlice";
import { GAEvent } from "../utils/events";

import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationButton = () => {
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated, user } = useAuth0();
  const state = useSelector(state => state.usercart);
  const navigate = useNavigate();
  const cart =  state.cart.products?.length > 0 

  const handleOpenCart = () => {
    GAEvent("cart", "open_cart", "button_press");
    navigate("/cart", { replace: true })
  }

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        dispatch(fetchUser(user));
      } else if (!state.user.name) {
        dispatch(createGuest())
      } 
    } 
  }, [user, isLoading])

    return (
    <Grid container sx={{width: cart ? {xs: 60, md: 115} : {xs: 50, md: 50}}} >  
      { isAuthenticated || state.user.name !== "Guest" ? <User /> : <LoginButton cart={cart} /> }
      {cart ?
      <Tooltip title="Go to active cart">
        <IconButton onClick={handleOpenCart} sx={{ width: {xs: 30, md: 50}, padding: 0, }}>
          <ShoppingCartCheckoutIcon/>
        </IconButton>
      </Tooltip> : null 
    }

    </Grid>
  )
 }

 export default AuthenticationButton;
