import {useState, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Divider, Menu, MenuItem, Modal, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useAlertContext } from '../components/AlertContext';

function FormDialog({mode, open, handleClose, title, etsyCode, setTitle, handleInput, handleChange, handleAdd}) {

  return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{mode === "add" ? "Add Collection" : "Edit Collection"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={title}
            name="title"
            onChange={handleInput}
            autoFocus
            margin="dense"
            id="title"
            fullWidth
            variant="standard"
          />
          <TextField
            label="EtsyCode"
            value={etsyCode}
            name="etsyCode"
            onChange={handleInput}
            autoFocus
            margin="dense"
            id="title"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={mode === "add" ? handleAdd : handleChange}>Save</Button>
        </DialogActions>
      </Dialog>
  );
}


function SelectCollection({ listings, listing, collections, open, handleClose, submitSelect }) {
  const [selectedCollections, setCollections] = useState([]);
  const [selected, setSelected] = useState();
  const [title, setTitle] = useState();
  const [etsyCode, setCode] = useState();
  const [mode, setMode] = useState("select");
  const [anchorEl, setAnchorEl] = useState(null)
  const alertContext = useAlertContext();
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);

  const handleInput = (e) => {
    if (e.target.name === "title") {
      setTitle(e.target.value)
    } else {
      setCode(e.target.value)
    }
  }

  const formClose = () => {
    setFormOpen(false);
  }

  const handleAddMode = () => {
    setMode("add");
    setFormOpen(true);
  }

  const handleEdit = () => {
    handleCloseMenu();
    setMode("select");
    setFormOpen(true);
  }

  const handleDelete = async () => {
    const foundListings = listings.filter(listing => listing.collections.includes(selected));
    const result = await alertContext.showConfirmation(
      { title: `There are ${foundListings.length} listings using this collection.`,
        cancelButton: "Cancel",
        okButton: "Delete"
      }
    );
    if (result) {
      await fetch(`/api/collections/delete/${selected}`);  
      formClose()
      navigate("/admin/listings")
    } else {
      formClose()
    }
  }

  const handleAdd = async () => {
    const body = {name: title, etsyCode: etsyCode}
    await fetch("/api/collections/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
      formClose()
      navigate("/admin/listings")
  }

  const handleChange = async () => {
    const body = {name: title, etsyCode: etsyCode}
    await fetch(`/api/collections/edit/${selected}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    formClose()
    navigate("/admin/listings")
  }


  const deleteCollection = (id) => {
    setCollections(selectedCollections.filter(collection => collection !== id));
  }

  const addCollection = (id) => {
    setCollections([...selectedCollections, id]);
  }

  const handleSelect = (e) => {
    const value = e.target.value;
    selectedCollections.includes(value) ? deleteCollection(value) : addCollection(value);
  }

  useEffect(() => {
    if (listing) {
      setCollections(listing.collections || [])
    }
  }, [listing])


  const processClose = () => {
    handleClose();
    submitSelect(selectedCollections);
  }

  const handleOpenMenu = (event) => {
    console.log(event.currentTarget)
    setSelected(event.currentTarget.id);
    setTitle(event.currentTarget.value);
    setCode(event.currentTarget.name);    
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Modal
      open={open}
      onClose={processClose}
    >
      <Card className="modal-box" sx={{padding: {xs: 2, lg: 0}}}>
        <CardHeader action={
          <IconButton onClick={processClose}>
            <CloseIcon />
          </IconButton>
        }/>
        <Typography fontSize={20} fontWeight="bold">
          Select Collections
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: 5,
            backgroundColor: "#9DD3D8"
          }}
        />
        <Grid container justifyContent="flex-start" padding={5} spacing={2}>
          <Menu
            id="menu-edit"
            anchorEl={anchorEl}
            keepMounted        
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
          </Menu>
          <FormDialog mode={mode} open={formOpen} title={title} setTitle={setTitle} etsyCode={etsyCode} setCode={setCode} handleInput={handleInput} handleChange={handleChange} handleClose={formClose} handleAdd={handleAdd}/>
          {collections.map((collection, index) => {
            return (
              <Grid item key={index} xs={2} align="left">
                <Button
                  value={collection._id} 
                  variant={selectedCollections.find(id => id === collection._id) ? 'contained' : 'outlined'}
                  onClick={handleSelect}
                >
                  {collection.name}
                </Button>
                <IconButton id={collection._id} value={collection.name} name={collection.etsyCode} onClick={handleOpenMenu}>
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            )
          }) }
        </Grid>
          <Button sx={{margin: 1}} variant='contained' onClick={handleAddMode}>Add a Collection</Button>
      </Card>
    </Modal>
  )      
}

export default SelectCollection;