import { useNavigate } from "react-router-dom";

import { useState } from "react";

import { Button, CardHeader, CardMedia, IconButton, Typography, Box } from "@mui/material";

import TouchAppIcon from '@mui/icons-material/TouchApp';

import { useAlertContext } from "./AlertContext";
import { useLoading } from "./LoadingContext";
import Map from "./Map";
import { coordinatesDistance } from "../utils/coordinatesDistance";

const GetMeThere = () => {
  const alertContext = useAlertContext();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [stage, setStage] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [polyline, setPolyline] = useState();
  const [distance, setDistance] = useState();
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(11);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [origin, setOrigin] = useState();
  const [caminoName, setName] = useState();
  const isApple = navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad") || navigator.userAgent.includes("Macintosh")
  const [kmlSource, setKmlSource] = useState();

  function setupMap(origin, stage) {
    const coordinates = stage.coordinates
    const minLat = Math.min(...coordinates.map(item => item.lat), origin.lat);
    const maxLat = Math.max(...coordinates.map(item => item.lat), origin.lat);
    const minLong = Math.min(...coordinates.map(item => item.long), origin.long);
    const maxLong = Math.max(...coordinates.map(item => item.long), origin.long);
    setHeight(coordinatesDistance(minLat, minLong, maxLat, minLong));
    setWidth(coordinatesDistance(minLat, minLong, minLat, maxLong));
    setCenter({lat: (minLat + maxLat) / 2, lng: (minLong + maxLong) / 2});
    const widthMetersPerPixel = width / window.innerWidth;
    setZoom(widthMetersPerPixel > 950 ? 6 
      : widthMetersPerPixel > 430 ? 7 
      : widthMetersPerPixel > 210 ? 8 
      : widthMetersPerPixel > 100 ? 9 
      : widthMetersPerPixel > 50 ? 10 
      : widthMetersPerPixel > 20 ? 11
      : 12 );  
  };

  async function success(position) {
    // testing ---------------------------
    // const lat = 43.2642951
    // const long = -2.9436521
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    //------------------------------------

    const nearestResponse = await fetch(`/api/neareststage?lat=${lat}&long=${long}`);
    const data = await nearestResponse.json()
    setStage(data.stage);
    setName(data.caminoName);
    setOrigin({lat: lat, lng: long});
    setCoordinates(data.coordinates);
    setKmlSource([{ id: data.stage._id, number: 0, kmlUri: `${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/kmls/${encodeURIComponent(data.stage.kmlUri)}`}]);

    // get the route
    const routeResponse = await fetch(`/api/route?olat=${lat}&olong=${long}&dlat=${data.coordinates.lat}&dlong=${data.coordinates.long}&mode=DRIVE`);
    const route = await routeResponse.json()
    if (route) {
      setDistance(route.distance);
      const poly = route.coordinates.map(coord => ({lat: coord[0], lng: coord[1]}));
      setPolyline(poly);
    }

    // setup the map to center and zoom combined routes
    setupMap({lat: lat, long: long}, data.stage);

    setLoading(false);    
  }

  async function error() {
    await alertContext.showConfirmation(
      {title: "Unable to retrieve your location. Sorry :(",
       cancelButton: "Continue",
      }
    );
    setLoading(false);    
    navigate(-1);
  } 

  async function getLocation() {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "prompt") {
            setLoading(true)
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "denied") {
            await alertContext.showConfirmation(
              {title: "To use this funciton we need your current location but your permissions are turned off. Please check location permission for your device and browser and then give permission for our website.",
              cancelButton: "Continue",
              }
            );    
            navigate(-1);
          }
        });
    } else {
      await alertContext.showConfirmation(
        {title: "To use this funciton we need your current location but your permissions are turned off. Please check location permission for your device and browser and then give permission for our website.",
          cancelButton: "Continue",
        }
      );    
      navigate(-1);
    }  
  }

  const minMapHeight = () => { 
    const metersPerPx = 156543.03392 * Math.cos(40 * Math.PI / 180) / Math.pow(2, zoom);    
    const minPixels = height / metersPerPx;
    const viewportHeight = (100 * (minPixels / window.innerHeight)) ;
    let vhHeight = Math.round(Math.max(viewportHeight, 40) + 10).toString();
    if (viewportHeight > 50) {
      setZoom(zoom - 1)
      vhHeight = "50";      
    }
    return `${vhHeight}vh`; 
  }

  return (
    <>
      <CardHeader 
        title="Get Me to the Camino"
        action={
          <IconButton 
            sx={{ml: {xs: "-37vw", sm: "-5vw", md: "-12vw", lg: "-18vw", xl: "-25vw"}, background: "#ff9800", color: "black"}} onClick={getLocation}>
            <TouchAppIcon />
          </IconButton>
        }
      />
      <div style={{ position: "relative" }}>
        <CardMedia
          width={1}
          component="img"
          alt="forest"
          src="/images/pages/forest2.png"
          sx={{mb: 2, height: {xs: "28vh", lg: "25vh"}}}
          style = {{
            filter: "brightness(50%)",
          }}
        />
        <Box
          sx={{
            position: "absolute", 
            minWidth: { xs: "80%", sm: "0%" },
            top: "10%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
        <Typography 
          sx={{
            letterSpacing: ".03em",
            textDecoration: "none",
            color: "white", 
            fontSize: {xs: 20, lg: 25},
            lineHeight: 1, 
            textAlign: "center",
          }}
        > 
          Your nearest camino: <br></br>{caminoName}<br></br><br></br>
          <Typography
            sx={{
              fontSize: {xs: 16, lg: 20},
              lineHeight: 1.2, 
            }}
          >
            Stage: {stage?.name}<br></br>
            Distance in kilometers: {distance ? (distance/1000).toFixed(2) : ""}
          </Typography>
          { isApple ?
          <Button 
            sx={{mt: 2, mr: 2}} 
            variant="contained"
            href={`http://maps.apple.com/?saddr=${origin?.lat},${origin?.lng}&daddr=${coordinates?.lat},${coordinates?.long}`}

          >
            Get Directions<br></br>Apple Maps
          </Button> : null }
          <Button 
            sx={{mt: 2}} 
            variant="contained"
            href={`http://www.google.com/maps/dir/?api=1&origin=${origin?.lat},${origin?.lng}&destination=${coordinates?.lat},${coordinates?.long}`}
            target="_blank"
          >
            Get Directions<br></br>Google Maps
          </Button>
          </Typography>
        </Box>
      </div>
    { polyline?.length > 0 && minMapHeight()?
    <Map 
      zoom={zoom}
      center={center}      
      style={{ height: minMapHeight()}}
      stageMarkers={[{name: "Your Location", ...origin }]}
      route={polyline}
      kmlSource={kmlSource}
    /> : null}
    </>
  )
};

export default GetMeThere;
