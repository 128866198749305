import React, { useState, useEffect, memo } from 'react';

import { Toolbar, IconButton, Menu, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from "./Logo";
import AuthNav from "./auth-nav";
import { menuItems } from '../models/menuitems';
import { MenuSub } from './MenuSub';
import { GAEvent } from '../utils/events';

function NavBar() {
  const [items, setItems] = useState([]);
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const [portrait, setPortrait] = useState(true)
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  useEffect(() => {
    async function getItems() {
      const items = await menuItems();
      setItems(items);
    }
    getItems();
  }, [])

  useEffect(() => {
    function checkOrientation() {
      setPortrait( window.innerWidth < window.innerHeight ? true : false) ;
    }
    window.addEventListener("resize", checkOrientation);
    checkOrientation();
    return () => window.removeEventListener("resize", checkOrientation);
  }, []);

  useEffect(() => {
    function handleWindowSizeChange() {
      setMobile(window.innerWidth <= 600);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);


  const handleOpenNavMenu = (event) => {
    GAEvent("caminos", "open_menu", "button_press");
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  return (
    <>
      <Toolbar disableGutters sx={{height: 80}}>  
        <Grid container direction="row" alignItems="center" justifyContent="space-around">
            <IconButton
              sx={{width: 50, transform: "scale(1.5)"}}
              size="large"
              aria-label="menu-appbar"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {items.map((item, index) => (
                <MenuSub key={index} item={item} closeMenu={handleCloseNavMenu} />
              ))}
            </Menu>
            <Logo mobile={mobile}/> 
            <AuthNav />
          </Grid>
        </Toolbar>
    </>
  );
}
export default memo(NavBar);
