// Update store from Etsy API
import { useState } from 'react';

import { useLoaderData } from "react-router-dom";

import { DataGrid, GridRowModes,  GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, useGridApiRef } from "@mui/x-data-grid";

import { Button, Box, Grid, IconButton, } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { updateDiscount, deleteDiscount, newDiscountObject, copyDiscount } from '../models/discount';
import ChooseCategory from '../components/ChooseCategory';
import { useAlertContext } from '../components/AlertContext';

export async function discountLoader() {
  let response = await fetch("/api/discounts/all");
  let discounts = await response.json();
  for (let di = 0; di < discounts.length; di++) {
    discounts[di].id = discounts[di]._id;
    discounts[di].startDate = new Date(discounts[di].startDate);
    discounts[di].endDate = new Date(discounts[di].endDate);
  }
  response = await fetch("/api/store/categories/all");
  let categories = await response.json();
  return { discounts, categories };                  
};

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const newObject = newDiscountObject();
    setRows((oldRows) => [...oldRows, newObject]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newObject.id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function Discount() {
  const { discounts, categories } = useLoaderData();
  const [rows, setRows] = useState(discounts);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openItems, setOpenItems] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState();
  const apiRef = useGridApiRef();
  const alertContext = useAlertContext();
  
  const handleItemsOpen = () => {
    setOpenItems(true);
  };

  const handleItemsClose = () => {
    setOpenItems(false);
  };

  const handleItemSelect = (selectedItems) => {
    let id;
    if (currentDiscount) {
      id = currentDiscount.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    apiRef.current.updateRows([{ id: id, items: selectedItems }]);
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCopyClick = (copyId) => async () => {
    let discount = rows.find((discount) => discount.id === copyId);
    const copyRow = await copyDiscount(discount);
    setRows((oldRows) => [...oldRows, copyRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [copyRow.id]: { mode: GridRowModes.Edit, fieldToFocus: 'sourceQty' },
    }));
  };

  const handleDeleteClick = (id) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    await deleteDiscount(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const verifyDates = async (discount) => {
    if (discount.startDate > discount.endDate) {
      await alertContext.showConfirmation(
        {title: "Ending date must be later than or equal to starting date.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (discount.endDate < new Date().setHours(0,0,0,0)) {
      await alertContext.showConfirmation(
        {title: "Ending date must be later than or equal to today.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }
  
  const verifyCart = async (discount) => {
    if (discount.cart && (discount.any || discount.items.length > 0)) {
      await alertContext.showConfirmation(
        {title: "If 'cart' is selected then do not select 'any' nor select any qualifying items",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyAny = async (discount) => {
    if (discount.any && discount.items.length > 0) {
      await alertContext.showConfirmation(
        {title: "If 'any' is selected then do not select any qualifying items",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyMinimums = async (discount) => {
    if (discount.minQty && discount.minAmount) {
      await alertContext.showConfirmation(
        {title: "Choose either a minimum quantity (units) or a minimum amount (value)",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const verifyDiscounts = async (discount) => {
    if (discount.discountAmount && discount.discountPercent) {
      await alertContext.showConfirmation(
        {title: "Choose either a discount amount or percent.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (discount.discountLimitQty && discount.discountLimitAmount) {
      await alertContext.showConfirmation(
        {title: "Choose either a quantity limit or an amount limit for the discount.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
    if (discount.discountPercent < 0 || discount.discountPercent > 100) {
      await alertContext.showConfirmation(
        {title: "Discount % must be between 0 and 100.",
         cancelButton: "Continue",
        }
      )
      return false;
    }
      return true;
  }

  const processRowUpdate = async (newRow, oldRow) => {
    var verified = false;
    verified = verifyDates(newRow);
    verified = verified && verifyCart(newRow);
    verified = verified && verifyAny(newRow);
    verified = verified && verifyMinimums(newRow);
    verified = verified && verifyDiscounts(newRow);
    if (verified) {
      newRow._id = newRow.id;
      console.log(newRow)
      await updateDiscount(newRow);  
      return newRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const handleItems = (discount_id) => {
    setCurrentDiscount(discounts.find(discount => discount.id === discount_id));
    setOpenItems(true);
  }

  const columns = [
    { field: 'name', headerName: "Name", editable: true, width: 100 }, 
    { field: 'description', headerName: "Description", editable: true, width: 250 },
    { field: 'startDate', headerName: "Start Date", editable: true, width: 90, type: "date",  },
    { field: 'endDate', headerName: "End Date", editable: true, width: 90, type: "date" },
    { field: 'cart', headerName: "Cart?", editable: true, width: 50, type: "boolean" },
    { field: 'any', headerName: "Any?", editable: true, width: 50, type:"boolean" },
    { field: 'minSelf', headerName: "Minimum applies to each item", editable: true, width: 100, type:"boolean"},
    { field: 'minQty', headerName: "Minimum Qty", editable: true, width: 70, type: "number"},
    { field: 'minAmount', headerName: "Minimum Amount", editable: true, width: 70, type: "number" },
    { field: 'discountAmount', headerName: "Discount Amt", editable: true, width: 70, type: "number" },
    { field: 'discountPercent', headerName: "Discount %", editable: true, width: 70, type: "number" },
    { field: 'disoucntLimitQty', headerName: "Discount Limit Units", editable: true, width: 70, type: "number" },
    { field: 'disoucntLimitAmount', headerName: "Discount Limit Value", editable: true, width: 70, type: "number" },
    { field: 'items', headerName: "Qualifying Items", width: 100,
      renderCell: (params) => 
        <IconButton 
          style={{color: "inherit"}} 
          onClick={() => handleItems(params.row.id)}>
            <AccountTreeIcon  />
        </IconButton>,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={<ContentCopyIcon />}
          label="Copy"
          className="textPrimary"
          onClick={handleCopyClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]
 
  return(
    <Box >
      <Grid container sx={{mt:2, ml:4}} alignItems="flex-start" justify="flex-start" direction="column" spacing={2}>  
        <ChooseCategory
          categories={categories} 
          open={openItems} 
          handleClose={handleItemsClose} 
          handleOpen={handleItemsOpen}
          discount={currentDiscount}
          submitSelect={handleItemSelect}
        />  
        <h1>Discounts Maintenance</h1>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{
            '.MuiDataGrid-columnHeader': {color: "blue"},
            "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          }
        }}
          getRowHeight={() => "auto"}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Grid>
    </Box>
  )

};

export default Discount;
