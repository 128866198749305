import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip2 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Unsure about where to start?
      </Typography>
      <Paper elevation={5} sx={{width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <a href="/Camino%20Franc%C3%A9s%20(French%20Way)/stages/27/6579a7c80945742b869779d5">Sarria Kick-Off 🚀</a>
          <ul>
            <li>Choice of 26% of Pilgrims</li>
            <li>Only 114 km from Santiago, it's perfect for first-timers.</li>
            <li>Expect picturesque landscapes, charming villages, and a bunch of cozy spots to rest.</li>
          </ul>
          <a href="/Camino%20Franc%C3%A9s%20(French%20Way)/stages/1/64512ab0a47a412943e6316f">Saint-Jean-Pied-de-Port Start 🌄</a>
          <ul>
            <li>Picked by 11% of Pilgrims</li>
            <li>A challenging but rewarding start, 781 km from Santiago in France.</li>
            <li>Get ready for stunning views along the Camino Francés.</li>
          </ul>
          Oporto Adventure 🌊
          <ul>
            <li>Chosen by 10% of Pilgrims</li>
            <li>A 231 km journey from Portugal's second-largest city.</li>
            <li>The Camino Portugués promises historical sites, rural landscapes, and charming towns.</li>
          </ul>
          Tui Exploration 🏞️
          <ul>
            <li>Selected by 5% of Pilgrims</li>
            <li>A quaint city in Pontevedra, just 115 km from Santiago on the Camino Portugués.</li>
            <li>Dive into unique Portuguese and Galician culture on this route.</li>
          </ul>
          <a href="/Camino%20Franc%C3%A9s%20(French%20Way)/stages/19/65799b2c0945742b8695cb55">León Discovery 🏰</a>
          <ul>
            <li>Opted by 4% of Pilgrims</li>
            <li>Starting 309 km away in Castilla y León, with a spectacular cathedral.</li>
            <li>Immerse yourself in cultural heritage and diverse landscapes.</li>
          </ul>
          Ferrol Kickstart 🚶
          <ul>
            <li>Chosen by 3% of Pilgrims</li>
            <li>Located 117 km from Santiago in the province of A Coruña.</li>
            <li>Shorter but no less impactful journey through the Camino Inglés.</li>
          </ul>
          <a href="/Camino%20Franc%C3%A9s%20(French%20Way)/stages/25/6579a2fc0945742b8696e2f6">O Cebreiro Challenge ⛰️</a> 
          <ul>
            <li>Picked by 3% of Pilgrims</li>
            <li>156 km from Santiago, this point in Galicia offers mountainous landscapes.</li>
            <li>Prepare for a challenging but visually rewarding experience.</li>
          </ul>
          Oviedo Original 🌳
          <ul>
            <li>Opted by 3% of Pilgrims</li>
            <li>Capital of Asturias, kicking off the 323 km Camino Primitivo.</li>
            <li>A tough yet authentic route with fewer crowds.</li>
          </ul>
          <a href="/Camino%20Franc%C3%A9s%20(French%20Way)/stages/23/6579a1d90945742b8696bbdf">Ponferrada Starting Point 🌄</a>
          <ul>
            <li>Chosen by 2% of Pilgrims</li>
            <li>206 km from Santiago, a balanced journey through the Camino Francés.</li>
            <li>Encounter diverse villages and historical landmarks.</li>
          </ul>
          Valença do Minho Launch 🌉
          <ul>
            <li>Picked by 2% of Pilgrims</li>
            <li>The last Portuguese town before crossing to Galicia.</li>
            <li>Whether starting here or in Tui, it's a short 120 km with breathtaking views.</li>
          </ul>
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip2;