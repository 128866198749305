export const footerItems = [
  { title: "PLAN",
    submenu: [
      {title: "The Caminos", url: "/caminos"},
      {title: "Lodging", url: "/lodging"},
      {title: "Services", url: "/services"},
      {title: "Tips", url: "/tips"},
      {title: "Tools", url: "/tools"},
    ]
  },
  { title: "SHOP",
    submenu: [
      {title: "Our Store", url: "/store"},
      {title: "Shipping & Delivery", url: "/shipping"},
      {title: "Returns & Exchanges", url: "/returns"},
    ]
  },
  { title: "ABOUT US",
    submenu: [
      {title: "About Us", url: "/about"},
      {title: "Sylvie's Blog", url: "/blogs"},
      {title: "Contact Us", url: "mailto:admin@mycamino3d.com", email: true},  
    ]
  },
  { title: "MORE",
    submenu: [
      {title: "Privacy & Cookies", url: "/privacy"},  
    ]
  },
];


