import { Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

export const AlertDialog = (props) => {

  const open = props.open
  const title = props.title

  return (
    <Dialog
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogActions>
        {props.buttons.map((button, index) => {
          return (
            <Button key={index} name={button.value || ""} onClick={button.action} autoFocus={index===0}>
              {button.msg}
            </Button>
          )
        })}
      </DialogActions>
    </Dialog>
  )
}
