import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip7 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Preparing to Walk the Camino
      </Typography>
      <Paper elevation={5} sx={{ width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <span style={{fontSize: 24}}>T</span>he Camino de Santiago is a treasure trove of discoveries, challenges, and unforgettable moments. Whether you're on a spiritual pilgrimage or diving into Spain's rich culture and history, get ready for an incredible experience.
          <br></br><br></br>To fully embrace the Camino de Santiago, you'll need to be well-prepared to tackle the challenges that await you over hundreds of kilometers. At Vive Camino, we're here to give you all the advice you need to prepare for your walk and make the most out of your experience. Let's jump in! 🌄
          <br></br><br></br><span style={{fontSize: 24}}>U</span>niversal Tips for Preparing the Camino de Santiago on Foot
          <br></br>When it comes to preparing for the Camino de Santiago, there are some universal tips that apply to all kinds of pilgrims, whether you're a seasoned traveler of this magnificent journey or embarking on it for the first time. These tips are essential building blocks that you should definitely consider, regardless of the route you choose. Grab a pen and paper – you'll want to jot these down! ✍️
          <br></br><br></br>🎒Physical preparation is important to complete the Camino de Santiago. We'll dive into this in more detail later, but make sure to reserve at least a couple of months to get into your best physical condition.
          <br></br><br></br>First-time pilgrims often wonder what to pack for the Camino de Santiago. Your backpack should weigh around 10% of your body weight, and you should consider everything you might need for your daily routine: clothing, toiletries, and more.
          <br></br><br></br>There's no one-size-fits-all when it comes to the best boots for the Camino de Santiago, but there are guidelines to find the perfect fit for you. Look for comfort, lightweight options, and waterproof capabilities to handle different terrains.
          <br></br><br></br>🗓️Planning your route is also crucial. While it's nearly impossible to get lost on the Camino de Santiago, having a map with your stopping points, daily distances, and rest areas will make your experience much smoother. Ready for an adventure? 🗺️
          <br></br><br></br><span style={{fontSize: 24}}>H</span>ow Should I Prepare for the Camino de Santiago on Foot?
          <br></br>The Camino de Santiago isn't your ordinary stroll – you probably know that by now. Regardless of the route you choose, it's an emotional journey that involves history, culture, and the natural beauty of the landscape. Keep in mind that hundreds of kilometers separate you from your goal, the Cathedral of Santiago. So, your main focus should be physical preparation. 🚶‍♂️
          <br></br><br></br>Physical preparation is essential for the Camino de Santiago, but you don't need to be an elite athlete. Developing reasonable physical fitness is enough. Start with regular walks of significant distances every day. As weeks pass, increase both your distance and the variety of terrains you cover. Gradually adding weight to your backpack is also a great training strategy.
          <br></br><br></br>Mental preparation is just as crucial. The Camino de Santiago can be emotionally challenging, and feelings of loneliness or exhaustion might come into play. Keep in mind that it's not just about the physical journey – it's also a mental and emotional one.
          <br></br><br></br>Remember, other aspects like route and stage planning, rest stops, and your gear are also important. Your equipment will be your best travel companion, from your footwear to your backpack. Embrace the journey with physical readiness, patience, and perseverance. You've got this! 💪
          <br></br><br></br><span style={{fontSize: 24}}>H</span>ow Many Days Do I Need to Complete the Camino de Santiago on Foot?
          <br></br>The number of days you need to complete the Camino de Santiago on foot largely depends on the route you choose and your walking pace. For example, the Camino Francés is one of the most popular routes, starting in Saint Jean Pied de Port (France) and covering about 800km to Santiago. This route takes an average of 30 to 35 days, with a daily distance of around 25 km.
          <br></br><br></br>But here's the thing – remember that the Camino de Santiago is not a race. It's a personal journey, whether for cultural or spiritual reasons. The true goal isn't just reaching Santiago; it's enjoying every single step of the journey, reflecting on history and culture, and soaking in the natural beauty along the way. Experience it at your own pace and in your own unique way. 🌍
          <br></br><br></br><span style={{fontSize: 24}}>H</span>ow Many Months of Physical Preparation Are Recommended Before the Camino de Santiago?
          <br></br>Typically, two to three months of physical preparation are recommended for the Camino de Santiago. While this timeframe can vary from person to person based on factors like current physical condition and the chosen route's length and difficulty, a couple of months is an optimal period for getting physically prepared.
          <br></br><br></br>As we mentioned earlier, the training doesn't have to be overly rigorous – consistency is key. Start with shorter walks and gradually increase both distance and the complexity of terrain. Incorporating a weighted backpack is a good idea, as it simulates what you'll experience on the Camino. Remember, the Camino de Santiago is both a physical and mental journey, so be sure to prepare yourself on both fronts. 🏞️🧠
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip7;