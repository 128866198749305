import {useState, useEffect} from 'react';

import { Button, Card, CardHeader, IconButton, Divider, Modal, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


function SelectCategory({ listing, categories, open, handleClose, submitSelect }) {
  const [selectedCategories, setCategories] = useState([]);

  const addCategory = (id) => {
    setCategories([...selectedCategories, id]);
  }

  const deleteCategory = (id) => {
    setCategories(selectedCategories.filter(category => category !== id));
  }

  const handleSelect = (e) => {
    const value = e.target.value;
    selectedCategories.includes(value) ? deleteCategory(value) : addCategory(value);
  }

  useEffect(() => {
    if (listing) {
      setCategories(listing.categories || [])
    }
  }, [listing])


  const processClose = () => {
    handleClose();
    submitSelect(selectedCategories);
  }

  return (
    <Modal
      open={open}
      onClose={processClose}
    >
      <Card className="modal-box" sx={{padding: {xs: 2, lg: 0}}}>
        <CardHeader action={
          <IconButton onClick={processClose}>
            <CloseIcon />
          </IconButton>
        }/>
        <Typography fontSize={20} fontWeight="bold">
          Select Categories
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: 5,
            backgroundColor: "#9DD3D8"
          }}
        />
        <Grid container justifyContent="flex-start" padding={5} spacing={2}>
          {categories.map((category, index) => {
            return (
              <Grid item key={index} xs={2} align="left">
                <Button
                  value={category._id} 
                  variant={selectedCategories.find(id => id === category._id) ? 'contained' : 'outlined'}
                  onClick={handleSelect}
                >
                  {category.name}
                </Button>
              </Grid>
            )

          }) }

        </Grid>
      </Card>
    </Modal>
  )      
}

export default SelectCategory;