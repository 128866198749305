// Holds all state data

import { configureStore } from "@reduxjs/toolkit";
import userCartReducer from "../models/user/userCartSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storageSession from 'redux-persist/lib/storage/session';
// import cartReducer from "../models/cart/cartSlice";

const persistConfig = {
  key: 'root',
  debug: true,
  storage,
}

const persistedReducer = persistReducer(persistConfig, userCartReducer)

export const store = configureStore({
  reducer: {
    usercart: persistedReducer,
  },
  devtools: process.env.NODE_ENV !== 'production',
  middleware: [thunk] 

})

export const persistor = persistStore(store);
  
