import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip5 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Walking the Camino Alone
      </Typography>
      <Paper elevation={5} sx={{ width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <span style={{fontSize: 24}}>E</span>mbarking on the Camino de Santiago is like stepping into a magical realm, and guess what? Whether you strut your stuff solo, with a sidekick, or in a squad, you're in for a ride of a lifetime!
          <br></br><br></br>          <span style={{fontSize: 24}}>A</span>dvantages of Strutting Solo
          <br></br>Feeling like a lone wolf 🐺? Hold up, it's not as dull as you think!
          <ul>
            <li>💭 Freedom Reigns: No one bosses you around. You decide the hows and whens, just as long as you keep strolling that Camino.</li>            
            <li>🌿 Nature Bonding: Dive deep into nature and self-discovery. Forget the hustle, and let your thoughts and ideas flow freely. It's a date with yourself!</li>            
          </ul>
          <span style={{fontSize: 24}}>D</span>isadvantages of the Solo Journey
          <ul>
            <li>🚨 Safety Check: Camino's generally safe, but keep an eye on your stuff. Common sense is your BFF.</li>
            <li>🧭 Navigating Nooks: Two pairs of eyes are better than one. Keep an eye out for those yellow arrows, and you'll be golden.</li>
          </ul>
          <span style={{fontSize: 24}}>A</span>dvantages of Squad Goals
          <br></br>Ready for a squad adventure? Get ready for a chatter-filled journey! 👭👬
          <ul>
            <li>🗣️ Constant Companionship: Laughs, shares, and talks – you're never alone. Good times or bad, your Camino comrades got your back.</li>
            <li>🤝 Helping Hands: Need assistance? Your buddies are there for the rescue. No dizzy spells or unexpected hurdles stand a chance!</li>
          </ul>
          <span style={{fontSize: 24}}>D</span>isadvantages of Squad Goals
          <br></br>But, hey, every rose has its thorns. 🌹
          <ul>
            <li>🚶‍♂️🚶‍♀️ Pace Predicament: Your rhythm may clash with your squad's. Flexibility is the name of the game. Are you up for it?</li>
          </ul>
          In the end, there's no one-size-fits-all on the Camino. Your journey, your rules! 🌄✨ Whether solo or in tandem, walking the Camino is a one-way ticket to an unforgettable experience. So, lace-up those shoes and hit the trail! You won't regret it! 🌟👣
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip5;