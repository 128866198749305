import { createAsyncThunk } from "@reduxjs/toolkit";

import { countries } from "../countries";
import axios from "axios";

export const getIp = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  if (res.status === 200) {
    const response = await fetch(`/api/location?ip=${res.data.ip}`);
    const data = await response.json();
    // if (data?.status === "success") {
    //   const locationString = JSON.stringify({countryIso: data.countryCode, currency: data.currency});
    //   localStorage.setItem("userLocation", locationString);
    // }
    return data.countryCode            
  }
  return "US"
};

export const getCurrencyFromIso = async (iso) => {
  const country = countries.find(country => country.iso === iso);
  const currency = country?.currency || "USD";
  const response = await fetch(`/api/convertcurrency/${currency}`);
  const rate = await response.json();
  return { currency: currency, rate: rate };
}

export function userLocation(user) {
  if (user) {
    const countryIso = user.shippingAddresses[0].countryIso || user.countryIso || null;
    if (countryIso) {
      const country = countries.find(country => country.iso === countryIso);
      const currency = country.currency;
      return { status: "success", countryCode: countryIso, currency: currency }
    }
  }
  return null;
}

export async function locationCurrency(address) {
  if (address) {
    let country = countries.find(country => country.iso === address.countryIso) || {currency: "USD"};
    const response = await fetch(`/api/convertcurrency/${country.currency}`);
    const rate = await response.json();  
    return { countryCode: address.countryIso, currency: country.currency, currencySymbol: country.currencySymbol, rate: rate, taxRate: country.taxRate,  }
  }
  return null;
}

export const updateUser = async(user_id, update) => {  
  console.log("update user")
  const result = await fetch(`/api/user/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(update)
  });
  const user = await result.json()
  return user;  
};



