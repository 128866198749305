import { memo } from "react"

import { Avatar, Grid } from '@mui/material';

import { Link } from 'react-router-dom';


import FooterMenu from './FooterMenu';

const Footer = function() {
  const mobile = window.innerWidth <= 600;

  return (
    <Grid sx={{ flexGrow: 1 }} container>
        <Grid container justifyContent="center" rowSpacing={{ xs: 5, lg: 0}} columnSpacing={{ xs: 5, lg: 10}} direction="row" sx={{marginTop: {xs: "20px", lg: "50px"}, minHeight: "200px"}}>
        {mobile ? null : 
         <Grid item xs={3}>
          <Link to={"/"}>
            <Avatar 
              sx={{
                width: "15vw",
                height: "15vw",          
              }} 
              src="/images/MyCamino3D_LOGO.png" 
              alt="logo"
            />
          </Link>
         </Grid> 
        }
      <FooterMenu />
      </Grid>
  </Grid>
  )
};

export default memo(Footer);

