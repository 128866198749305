import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import RouteProvider from './Route';

ReactGA.initialize("G-YT26ELQ07D");

ReactDOM.createRoot(document.getElementById("root")).render(
//  <React.StrictMode>  DISABLED BECAUSE OF PROBLEM WITH GOOGLE-MAP-REACT
      <RouteProvider />
//  </React.StrictMode>
);

