import React from 'react';

import { Link } from 'react-router-dom'

import { Box, Typography, List, ListItem, Paper } from '@mui/material';

const Tips = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Camino Tips and FAQs
      </Typography>
      <Paper elevation={5} sx={{width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, pl: {xs: [0, 2], lg: 5}, border: 2}}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItem component={Link} to={"/tips/1"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            What is Compostela?
          </ListItem>
          <ListItem component={Link} to={"/tips/2"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            Where to Start?
          </ListItem>
          <ListItem component={Link} to={"/tips/3"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            What to Pack?
          </ListItem>
          <ListItem component={Link} to={"/tips/4"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            Best Times?
          </ListItem>
          <ListItem component={Link} to={"/tips/5"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            Can I Do the Camino Alone?
          </ListItem>
          <ListItem component={Link} to={"/tips/6"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            Where to Sleep?
          </ListItem>
          <ListItem component={Link} to={"/tips/7"} sx={{ textDecoration: "none", color: "black", fontSize: 18, display: 'list-item' }}>
            Preparing to Walk the Camino
          </ListItem>
        </List>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tips;
