import { useLoaderData } from "react-router-dom";

import { Box, Card, CardHeader, CardContent, Grid, Typography } from "@mui/material";

function BlogPage() {
  let blog = useLoaderData();

  const couponPattern = new RegExp(/<div class="coupon"(.*)<\/div>/, "gs");
  const namePattern = new RegExp(/{(.*)}/, "g");
  
  blog.post = blog.post.replace(/max-width: 600px;/g, "");
  blog.post = blog.post.replace(/width: 600px;/g, "");
  blog.post = blog.post.replace(/width="600"/g, "");
  blog.post = blog.post.replace(/font-size: 12px;/g, "font-size: 14px;");
  blog.post = blog.post.replace(/margin: 0px;/g, "margin-bottom: 10px;");
  blog.post = blog.post.replace(couponPattern, "");
  blog.post = blog.post.replace(namePattern, "")
  blog.title = blog.title.replace(namePattern, "")
  
  return (
    <Box sx={{marginLeft: {xs: 0, lg: 5}}}>
      <Typography mt={3} fontSize={{xs: 30, md: 40}} align="center">Sylvie's Blog</Typography> 
        <Card sx={{ width: { xs: "100vw", lg: "90vw" }, mt: 2 }}>
          <CardHeader
            title={blog.title}
            subheader={blog.date ? blog.date.slice(0, 10) : ""}
          />
          <CardContent>
            <Grid container item alignItems="center" justifyContent="center" >
              <div
                dangerouslySetInnerHTML={{__html: blog.post}}
              >
              </div>
            </Grid>
          </CardContent>
        </Card>
    </Box>
  );
}  

export default BlogPage;