import React from 'react';

import { useLoaderData } from 'react-router-dom';

import { Box, Grid, Card, CardHeader, Typography } from '@mui/material';

import GetMeThere from '../components/GetMeThere';
import Distance from '../components/Distance';

const Tools = () => {
  const caminos = useLoaderData();
  return (
    <Box disableGutters sx={{
      width: '100%', 
      minHeight: "95vh",
      }}
    >
      <Typography align="center" padding={2} fontSize={{xs: 25, md: 35}} fontWeight="bold" height={70}  >
        Camino Planning Tools
      </Typography>
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={12} >
          <Card sx={{marginTop: {xs: -2, md: 0}}} >
            <GetMeThere />
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card sx={{marginTop: {xs: -2, md: 0}}} >
            <Distance caminos={caminos}/>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tools;
