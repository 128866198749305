import {useState, useEffect} from 'react';

import { Button, Card, TextField, CardHeader, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Modal, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function SelectProduct({ listing, rate, open, handleClose, submitChanges }) {
  const [dialogAction, setAction] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [priceValue, setPriceValue] = useState(0);
  const [pricePercent, setPricePercent] = useState(0);
  const [marginValue, setMarginValue] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (listing && listing.products?.length > 0) {
      let products = listing.products;
      products.sort((a,b) => a.color?.color.localeCompare(b.color?.color) || 
        a.size?.size.localeCompare(b.size?.size) || 
        a.style[0]?.styles?.value.localeCompare(b.style[0]?.styles?.value) ||
        a.style[1]?.styles?.value.localeCompare(b.style[1]?.styles?.value));
      setProducts(products.map(product => ({...product, price: product.price.toFixed(2).toString()})));
      setPriceValue(listing.price);
      setMarginValue(listing.products[0].cost ? ((1 - listing.products[0].cost / listing.products[0].price) * 100).toFixed(1) : 0);
    }
  }, [listing]);

  const processClose = () => {
    handleClose();
  }

  const processSubmit = () => {
    submitChanges(products);
    handleClose();
  }

  const handleInputChange = (index, value) => {
    setProducts(prevState => {
      const newData = [...prevState];
      newData[index] = {...newData[index], price: value};      
      return newData
    })
  };


  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const setPrice = () => {
    setAction("price");
    setDialogOpen(true);
  }

  const adjustPrice = () => {
    setAction("adjust");
    setDialogOpen(true);
  }

  const setMargin = () => {
    setAction("margin");
    setDialogOpen(true);
  }

  const handleChange = (e) => {
    if (dialogAction === "price") {
      setPriceValue(e.target.value)
    } else if (dialogAction === "adjust") {
      setPricePercent(e.target.value)
    } else {
      setMarginValue(e.target.value)
    }
  }

  const handleSubmit = () => {
    let newProducts = products;
    for (let i = 0; i < newProducts.length; i++) {
      if (dialogAction === "price") {
        newProducts[i].price = Number(priceValue).toFixed(2).toString();
      } else if (dialogAction === "adjust") {
        const newPrice = (newProducts[i].price * (1 + pricePercent / 100));
        newProducts[i].price = newPrice;
      } else {
        const newPrice = (newProducts[i].cost / (1 - marginValue / 100));
        newProducts[i].price = newPrice;
      }      
    }
    setProducts(newProducts);
    setDialogOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={processClose}
    >
    <>
      <Dialog open={dialogOpen} onClose={handleDialogClose} >
        <DialogTitle>{dialogAction === "price" ? "Price" : dialogAction === "adjust" ? "Adjust" : "Margin"}</DialogTitle>
        <DialogContent align="center">
          <DialogContentText>
            {dialogAction === "price" ? "Enter the new price for all products." : dialogAction === "adjust" ? "Enter percent change for all products" : "Enter the desired profit margin and the prices will be automatically reset."}
          </DialogContentText>
          { dialogAction === "price" ? "$ " : null}
          <TextField
            autoFocus
            id={dialogAction}
            value={dialogAction === "price" ? priceValue : dialogAction === "adjust" ? pricePercent : marginValue}
            type="number"
            variant="standard"
            sx={{width: 50}}
            onChange={handleChange}
          />
          { dialogAction === "margin" ? <span style={{algin: "flush"}}>%</span> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Card className="modal-box" sx={{overflowY: "auto", padding: {xs: 2, lg: 0}}}>
        <CardHeader action={
          <IconButton onClick={processClose}>
            <CloseIcon />
          </IconButton>
        }/>
        <Typography fontSize={20} fontWeight="bold">
          {listing?.shortTitle || listing?.etsyTitle}
        </Typography>
        <Button variant="contained" onClick={setPrice}>Set Global Price</Button>        
        <Button sx={{ml: 1}} variant="contained" onClick={adjustPrice}>Adjust Global Price</Button>        
        {products?.find(product => product.cost) ? <Button sx={{ml: 1}} variant="contained" onClick={setMargin}>Set Global Margin</Button> : null}        
        <Divider
          sx={{
            mt: 1,
            borderBottomWidth: 5,
            backgroundColor: "#9DD3D8"
          }}
        />
        <Typography align="center" mt={1} variant='h5'>Prices are in Euros</Typography>
        <Grid container justifyContent="flex-start" padding={2} spacing={2}>
          {products?.map((product, index) => {
            return (
              <Grid item key={index} xs={2} align="left">
                {product.style?.map((style, index) => {
                  return (
                    <Typography key={index}>
                      {style.styles.value}
                    </Typography>
                  )
                })}
                {product.color ?
                  <Typography>
                    Color: {product.color.color}
                  </Typography>
                : null
                }
                {product.size ?
                  <Typography>
                    Size: {product.size.size}
                  </Typography>
                : null
                }
                {product.cost ?
                  <Typography>
                    Cost: {(product.cost).toFixed(2)}
                  </Typography>
                : null
                }
                {product.cost ?
                  <Typography>
                    Gross Margin: {((product.price - product.cost) * 100 / product.price).toFixed(1)} %
                  </Typography>
                : null
                }
                <TextField 
                  id = {index.toString()}
                  size="small"
                  label="Price" 
                  value = {product.price}
                  onChange = {(e) => handleInputChange(index, e.target.value)}
                  variant='filled' />
                <Typography>US$ Price: {(Number(product.price) * rate).toFixed(2)}</Typography>
              </Grid>
            )
          })}
        </Grid>
          <Button onClick={processClose}>Cancel</Button>
          <Button onClick={processSubmit}>Submit</Button>
      </Card>
      </>
    </Modal>
  )      
}

export default SelectProduct;