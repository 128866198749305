import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import { resetAll } from "../models/user/userCartSlice";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  return (
    <button
      className="btn btn-danger btn-block"
      onClick={() => {
        dispatch(resetAll());
        logout({
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
          returnTo: window.location.origin,
        })
      }}
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
