import {useState, useEffect} from 'react';

import { Button, Card, CardHeader, IconButton, Divider, Modal, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ChooseItems from './ChooseItems';


function ChooseCategory({ discount, categories, open, handleOpen, handleClose, submitSelect }) {
  const [selectedCategory, setCategory] = useState();
  const [selectedItems, setSelect] = useState([]);
  const [openItems, setOpenItems] = useState(false);

  function addItem(item) {
    setSelect(prevItems => {
      return [...prevItems, item]
    });
  }  

  function deleteItem(item) {
    setSelect(prevItems => { return prevItems.filter(selected => selected !== item)});
  }

  const handleSelect = ({type, value, status}) => {
    if (type === "category") {
      const category = categories.find(category => category._id === value)
      for (const item of category.listings) {
        status ? addItem(item._id) : deleteItem(item._id);
      }
    } else {
      status ? addItem(value) : deleteItem(value);
    }
  }

  const handleItemsOpen = () => {
    setOpenItems(true);
  };
  const handleItemsClose = () => {
    setOpenItems(false);
  };

  useEffect(() => {
    if (discount) {
      setSelect(discount.items?.map(item => item._id).flat() || [])
    }
  }, [discount])

  const handleOpenCategory = (e) => {
    setCategory(categories.find(category => category._id === e.target.value))
    setOpenItems(true)
  }

  const processClose = () => {
    handleClose();
    submitSelect(selectedItems);
  }

  return (
    <Modal
      open={open}
      onClose={processClose}
    >
      <Card className="modal-box" sx={{padding: {xs: 2, lg: 0}}}>
        <ChooseItems 
          category={selectedCategory} 
          selectedItems={selectedItems}
          open={openItems}
          handleClose={handleItemsClose}
          handleOpen={handleItemsOpen}
          handleSelect={handleSelect}  
        />
        <CardHeader action={
          <IconButton onClick={processClose}>
            <CloseIcon />
          </IconButton>
        }/>
        <Typography fontSize={20} fontWeight="bold">
          Select Items
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: 5,
            backgroundColor: "#9DD3D8"
          }}
        />
        <Grid container justifyContent="flex-start" padding={5} spacing={2}>
          {categories.map((category, index) => {
            return (
              <Grid item key={index} xs={2} align="left">
                <Button
                  value={category._id} 
                  variant={category.listings.find(listing => selectedItems.find(item => item === listing._id)) ? 'contained' : 'outlined'}
                  onClick={handleOpenCategory}
                >
                  {category.name}
                </Button>
              </Grid>
            )

          }) }

        </Grid>
      </Card>
    </Modal>
  )      
}

export default ChooseCategory;