import {useState, useEffect} from 'react';

import { Button, Card, IconButton, Checkbox, Modal, Grid, CardHeader, CardMedia } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function ChooseItems({ selectedItems, category, open, handleOpen, handleClose, handleSelect }) {
  const [listings, setListings] = useState([]);

  useEffect(() => {
    if (category) {
      setListings(category.listings);
    }
  }, [category])

  const checkboxChange = (e) => {
    handleSelect({type: "item", value: e.target.value, status: e.target.checked});
  }
 
  const toggleAll = () => { 
    const status = Boolean(selectedItems.find(item => category.listings.find(listing => item === listing._id)));
    handleSelect({type: "category", value: category._id, status: !status});
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card 
        className="modal-box" 
        sx={{
          padding: {xs: 2, lg: 0},
          overflowY: "auto"
        }}>
        <CardHeader 
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
          sx={{height: 10}}  
        />        
        <Button variant='contained' onClick={toggleAll}>
          {listings.find(listing => selectedItems.find(item => item === listing._id)) ? "UnSelect All" : "Select All"} 
        </Button>
        <Grid container justifyContent="flex-start" pl={5} pr={5} spacing={2}>
          {listings.map((listing, index) => {
            return (
              <Grid item key={index} xs={2} align="left">
                <Card >
                  <CardHeader
                    action={
                      <Checkbox 
                        checked={Boolean(selectedItems.find(item => item === listing._id))} 
                        onChange={checkboxChange} 
                        value={listing._id}                           
                      />
                    }
                    subheader={listing.etsyTitle.slice(0, 40)}
                  />
                  <CardMedia 
                    component="img" 
                    width="100%"
                    image={`${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/listingsimages/${listing.imageUrls[0].thumbnail}`}
                    alt=""
                  />
                </Card>
              </Grid>
            )

          }) }
        </Grid>
      </Card>
    </Modal>
  )      
}

export default ChooseItems;