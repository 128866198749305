import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { userMenuItems } from "../models/userMenuItems";
import { MenuSub } from "./MenuSub";
import LogoutButton from "./logout-button";
import { getUser } from "../models/user/userCartSlice";

function User() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const user = useSelector(getUser);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const editAccount = () => {
  //   navigate("/account", { replace: true });
  // };


  // const selectLikes = () => {
  // };

  // const maintenance = () => {

  // }

  return (
    <Box >
    <Tooltip title={user.name}>
      <IconButton onClick={handleOpenUserMenu} sx={{ width: {xs: 30, md: 50}, p: 0 }}>
        <Avatar sx={{width: {xs: 30, md: 40}, height: {xs: 30, md: 40}}} alt={user.name} src={user.picture} />
      </IconButton>
    </Tooltip>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {userMenuItems.map((item, index) => (
        <MenuSub key={index} currentUser={user} item={item} closeMenu={handleCloseUserMenu} />        
      ))}
      <MenuItem>
        <LogoutButton />
      </MenuItem> 
    </Menu>
    </Box>  
  )
};
export default User;



