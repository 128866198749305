
export async function updateSavedCart(cart_id, update) { 
  console.log(cart_id) 
  console.log(update)
  const result = await fetch(`/api/invoice/${cart_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(update)
  });

  return result.json();  
};

export async function newCart(update) { 
  console.log("new cart called with: ", update) 
  const result = await fetch("/api/invoice/new", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(update)
  });  
  const toReturn = result.json();
  return toReturn;  
};



 


