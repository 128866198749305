import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchUser, getUser } from "../models/user/userCartSlice";

const ProtectedComponent = ({ component, ...propsForComponent}) => {
  const Cp = withAuthenticationRequired(component);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const fullUser = useSelector(getUser)
  const navigate = useNavigate();

  useEffect(() => {
    async function doAdd() {
      dispatch(fetchUser(user));
    };
    if (user) {
      doAdd();
      if (propsForComponent.role === "admin") {
        if (fullUser.role !== "admin") {
          navigate("/");
        }
      } 
      if (propsForComponent.role === "user") {
        if (propsForComponent.userId !== fullUser._id) {
          navigate("/");
        }
      }
    }
  }, [user] );

  const props = {...propsForComponent, user};
  return <Cp {...props} />
}

export default ProtectedComponent;