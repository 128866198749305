import { Typography } from '@mui/material';

function NotFound({ message = "Sorry, but we can't find this page!"}) {

  return(
      <Typography align="center" marginTop={20} marginBottom={30} variant="h3">
        {message}
      </Typography>
  )

};

export default NotFound;
