import React, { useState, useRef, useContext } from "react";

import { Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

const AlertContext = React.createContext({});

export const AlertProvider = ({children}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [props, setProps] = useState({});
  const resolver = useRef();

  const handleShow = (props) => {
    setProps(props);
    setShowAlertDialog(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const alertContext = {
    showConfirmation: handleShow
  }

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowAlertDialog(false)
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowAlertDialog(false)
  };

  return (
    <AlertContext.Provider value={alertContext}>
      {children}

      <Dialog
        open={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
      >
      <DialogTitle id="alert-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel}>{props.cancelButton}</Button>
        {props.okButton ? <Button onClick={handleOk}>{props.okButton}</Button> : null }
      </DialogActions>
    </Dialog>
    </AlertContext.Provider>
  )
};

export const useAlertContext = () => useContext(AlertContext);
export default AlertProvider;


