import {useState, useEffect} from 'react';

import { Button, Card, TextField, CardHeader, IconButton, Divider, Modal, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function SelectPrices({ listing, rate, open, handleClose, submitChanges }) {
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [styles, setStyles] = useState([]);

  useEffect(() => {
    if (listing) {
      setSizes(listing.sizes);
      setColors(listing.colors);
      setStyles(listing.styles);
    }
  }, [listing]);

  const processClose = () => {
    handleClose();
  }

  const verifyStyles = () => {
    let newStyles = styles;
    for (let i = 0; i < newStyles.length; i++) {
      let newStyle = newStyles[i];
      for (let j = 0; j < newStyle.styles.length; j++) {
        let item = newStyle.styles[j];
        if (typeof item.overridePrice === "string") {
          item.overridePrice = Number(item.overridePrice)
          if (item.overridePrice === 0) {
            delete item.overridePrice;
          }
          newStyle.styles[j] = item;
          newStyles[i] = newStyle;
        }
      }
    }
    return newStyles;
  }

  const verifyColors = () => {
    let newColors = colors;
    for (let i = 0; i < newColors.length; i++) {
      let color = newColors[i];
      if (typeof color.overridePrice === "string") {
        color.overridePrice = Number(color.overridePrice)
        if (color.overridePrice === 0) {
          delete color.overridePrice;
        }
        newColors[i] = color;
      }
    }
    return newColors;
  }

  const verifySizes = () => {
    let newSizes = sizes;
    for (let i = 0; i < newSizes.length; i++) {
      let size = newSizes[i];
      if (typeof size.overridePrice === "string") {
        size.overridePrice = Number(size.overridePrice)
        if (size.overridePrice === 0) {
          delete size.overridePrice;
        }
        newSizes[i] = size;
      }
    }
    return newSizes;
  }

  const processSubmit = () => {
    const newStyles = verifyStyles();
    const newSizes = verifySizes();
    const newColors = verifyColors();
    submitChanges({newStyles: newStyles, newSizes: newSizes, newColors: newColors});
    handleClose();
  }

  const handleStyleChange = (e) => {
    const value = e.target.value;
    const category = e.target.id.slice(0, 1);
    const index = e.target.id.slice(-1);
    let thisStyles = styles;
    let thisStyle = thisStyles[category];
    let innerStyles = thisStyle.styles
    innerStyles[index] = {...innerStyles[index], overridePrice: value};
    thisStyle.styles = innerStyles;
    thisStyles[category] = thisStyle;
    setStyles(thisStyles);
  };

  const handleSizeChange = (index, value) => {
    setSizes(prevState => {
      const newData = [...prevState];
      newData[index] = {...newData[index], overridePrice: value}
      return newData;
    })
  };

  const handleColorChange = (index, value) => {
    setColors(prevState => {
      const newData = [...prevState];
      newData[index] = {...newData[index], overridePrice: value};      
      return newData
    })
  };


  return (
    <Modal
      open={open}
      onClose={processClose}
    >
    <>
      <Card className="modal-box" sx={{overflowY: "auto", padding: {xs: 2, lg: 0}}}>
        <CardHeader action={
          <IconButton onClick={processClose}>
            <CloseIcon />
          </IconButton>
        }/>
        <Typography fontSize={20} fontWeight="bold">
          {listing?.shortTitle || listing?.etsyTitle}
          {" - Enter Override Prices Only (in euros)"}
        </Typography>
        <Typography fontSize={16} fontWeight="bold">
          Default Price: €{listing?.price}
        </Typography>
        <Divider
          sx={{
            mt: 1,
            borderBottomWidth: 5,
            backgroundColor: "#9DD3D8"
          }}
        />
          {styles.map((style, styleIndex) => {
            return (
              <Grid container direction="row" padding={4} spacing={1} justifyContent="center">
                <Typography width="100%" variant = "h5" key={styleIndex}>
                    {style.category}
                </Typography>
                {style.styles?.map((innerStyle, index) => {
                  return (
                    <Grid item key={index} xs={2} mt={2}>
                      <Typography key={index}>
                        {innerStyle.value}
                      </Typography>
                      <TextField 
                        id = {`${styleIndex}-${index}`}
                        size="small"
                        label="Override Price" 
                        value = {style.styles[index].overridePrice}
                        onChange={handleStyleChange}
                        // onChange = {(e) => handleStyleChange(index, e.target.value)}
                        variant='filled' />
                    </Grid>
                )})}
              </Grid>
            )
          })}
          { sizes.length > 0 ? 
            <Grid container direction="row" padding={4} spacing={1} justifyContent="center">
              <Typography width="100%" variant = "h5" >
                Sizes
              </Typography>
              {sizes.map((size, index) => {
                return (
                  <Grid item key={index} xs={2} mt={2}>
                    <Typography key={index}>
                      {size.size}
                    </Typography>
                    <TextField 
                      id = {index.toString()}
                      size="small"
                      label="Price" 
                      value = {sizes[index].overridePrice}
                      onChange = {(e) => handleSizeChange(index, e.target.value)}
                      variant='filled' />
                  </Grid>
                )})}
            </Grid> 
          : null}
          { colors.length > 0 ? 
            <Grid container direction="row" padding={4} spacing={1} justifyContent="center">
              <Typography width="100%" variant = "h5" >
                Colors
              </Typography>
              {colors.map((color, index) => {
                return (
                  <Grid item key={index} xs={2} >
                    <Typography key={index}>
                      {color.colr}
                    </Typography>
                    <TextField 
                      id = {index.toString()}
                      size="small"
                      label="Price" 
                      value = {colors[index].overridePrice}
                      onChange = {(e) => handleColorChange(index, e.target.value)}
                      variant='filled' />
                  </Grid>
                )})}
            </Grid>
          : null }
          <Button onClick={processClose}>Cancel</Button>
          <Button onClick={processSubmit}>Submit</Button>
      </Card>
      </>
    </Modal>
  )      
}

export default SelectPrices;