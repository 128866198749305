import React from 'react';

import { Box, Typography } from '@mui/material';

const Shipping = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("/images/camino-de-santiago-en-bici-desde-leon-900x506.jpeg")'
      }}>
      <Typography sx={{pt: 5, pl: 5, pb: 2 }} variant="h4">
      Shipping and Delivery Policy
      </Typography>
      <Typography sx={{pl: 5, pr: 5, fontSize: 16, fontWeight: "bold", color: "black"}} >
        Welcome to MyCamino3D! We're excited to get your goodies to you as quickly and smoothly as possible. Here's the lowdown on our shipping and delivery policy:        <ul>
          <li>Processing Time:</li>
          <ul>
            <li>Orders typically take 2 to 5 business days to process before they're shipped out. Once your order is shipped, you'll receive a confirmation email with tracking information if available so you can keep an eye on its journey to you.</li>
          </ul><br></br>
          <li>Shipping Methods:</li>
          We offer several shipping options to suit your needs:
          <ul>
            <li>Standard Shipping: Standard Post Office</li>
            <li>Expedited Shipping: Various couriers </li>
            <li>International Shipping: Standard international ROW post office</li>
          </ul>
          <br></br>
          <li>Shipping Costs:</li>
            <ul>
              <li>Shipping costs vary depending on your location, chosen shipping method, and order size. You can see the shipping cost for your order at checkout before you finalize your purchase.</li>
            </ul>
           <br></br>
           <li>Free Shipping:</li>
              <ul>
                <li>We offer free standard shipping on orders over $ 200.</li>
              </ul>
           <br></br>
           <li>Delivery Times:</li>
              <ul>
                <li>Delivery times vary depending on your location and the shipping method you choose. While we strive to get your order to you as quickly as possible, please note that delivery times may be affected by factors beyond our control, such as weather conditions or carrier delays.</li>
              </ul>
           <br></br>
           <li>International Shipping:</li>
              <ul>
                <li>For our international customers, please be aware that customs duties, taxes, and fees may apply upon delivery. These charges are the responsibility of the recipient and are not included in the shipping cost.</li>
              </ul>
           <br></br>
           <li>Order Tracking:</li>
              <ul>
                <li>Once your order is shipped, you'll receive a tracking number via email so you can track your package's journey every step of the way.</li>
              </ul>
           <br></br>
           <li>Questions or Concerns?</li>
            <ul>
              <li>If you have any questions or concerns about shipping or delivery, don't hesitate to reach out to us at <a  href="mailto:admin@mycamino3d.com"> admin@mycamino3d.com </a>. We're here to help!</li>
            </ul>
        </ul>
        Thanks for shopping with MyCamino3D! We appreciate your support.
      </Typography>
    </Box>
  );
};

export default Shipping;
