import { Avatar, Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

function Logo({footer}) {
  return (
    <Box 
      component={Link} to={"/"}
      className="logo"
      sx={{
        display: 'flex',
        width: {xs: 150, md: 250},
        textDecoration: 'none'
      }} >
      <Avatar 
        sx={{
          width: footer ? {xs: 60, md: 80} : {xs: 30, md: 40},
          height: footer ? {xs: 60, md: 80} : {xs: 30, md: 40},          
          }} 
        src="/images/MyCamino3D_LOGO.png" 
        alt="logo"/>
      <Typography
        noWrap
        sx={{
          fontSize: {xs: "15px", md: "25px"},
          mt: .5,
          ml: 1,
          fontWeight: 600,
          letterSpacing: -1,
          color: 'black',
          "&:hover": {color: "#fdd008"}, 
        }}
      >
        My Camino 3D
      </Typography>
    </Box> 
  )  
};
export default Logo;
