import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip1 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        What is the Camino de Santiago?
      </Typography>
      <Paper elevation={5} sx={{width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <span style={{fontSize: 24}}>W</span>hy do people embark on a pilgrimage to Santiago de Compostela? I bet you're curious! Well, the journey dates back over a thousand years, supposedly starting when they found the remains of Apostle Santiago el Mayor, the evangelizer of the Iberian Peninsula, in what we now know as Santiago de Compostela. Santiago's tomb, discovered during the reign of Alfonso XII El Casto, became a focal point for pilgrims from all over Europe, starting in the 10th and 11th centuries. But the real boom of the Camino de Santiago kicks off in the 12th century when Santiago de Compostela is equated in importance as a pilgrimage center to other significant Christian sites like Rome and Jerusalem.
          <br></br><br></br>🌟 Picture this: a first cathedral is built over the tomb of Santiago el Mayor, and around it grows the present-day city of Santiago de Compostela, the end of the Camino de Santiago. In the northern part of the Peninsula, different pilgrimage routes are established, crisscrossing from east to west, with significant paths like the Camino Primitivo or the Camino del Norte. Despite that, the Camino Francés ends up prevailing over others, thanks to the progress of the Reconquista by Christian kingdoms, leaving other more northern routes in the shadows. The Camino Francés plays a crucial role in the development of pilgrimage movements in Spain and southern Europe, giving rise to an influential trade route, flourishing with significant stopping points like Santo Domingo de la Calzada, Puente la Reina, or O Cebreiro, to name a few.
          <br></br><br></br>Although the number of pilgrims to Santiago significantly declined after the Middle Ages, with the onset of the Renaissance and the Protestant Reformation, the Camino de Santiago never completely disappeared. The Jacobean tradition has persisted in the towns crossed by pilgrimage routes even in a time when the Camino was somewhat forgotten in Europe.
          <br></br><br></br>In the late 19th century, the Camino experienced a decisive moment with the excavations carried out by Antonio López Ferreiro in the Cathedral of Santiago. Supposedly finding the remains of three individuals at a depth of 30 meters, identified as Santiago El Mayor and his disciples Atanasio and Teodoro. This discovery was endorsed by Pope Leo XIII after an investigation by the Catholic Church, promulgating the bull Deus Omnipotens, which once again promoted the pilgrimage of the faithful to Santiago de Compostela.
          <br></br><br></br>The figure of the pilgrim has historically been protected by the highest religious and monarchic authorities. Safe-conducts, recommendation letters, and certificates issued by various royal houses and religious orders allowed pilgrims to transit relatively safely on the Jacobean route. There were severe penalties for those who caused any trouble or extortion to the pilgrim, especially in an era when continuous wars ravaged the European continent. Favors toward the religious penitent went further, exempting them from tolls and fees while obliging others to provide them with covered accommodation.
          <br></br><br></br><span style={{fontSize: 24}}>W</span>hat's the purpose of this pilgrimage?
          <br></br><br></br>Every year, thousands of people from around the world lace up their best boots for the Camino de Santiago, embarking on a journey of hundreds of kilometers. The objective of this pilgrimage is as diverse as the pilgrims themselves. An experience that transcends religion, age, ethnic origin, or physical condition. Each person can have their own motivation for walking the Camino de Santiago, but they all share a common goal: reaching the majestic cathedral of Santiago de Compostela.
          <br></br><br></br>For some pilgrims, the Camino de Santiago represents a spiritual experience, a retreat from the hustle and bustle of city life; it's also an opportunity for reflection and connection with oneself and nature. Additionally, a pilgrim might embark on the Camino de Santiago to honor a promise or a lost loved one.
          <br></br><br></br>Others put on their boots and embark on this adventure to test their physical condition, a way to challenge and overcome their own limits. Some consider the Camino de Santiago a hiking challenge, a test of endurance and strength. As we've mentioned many times, remember that the Camino de Santiago is not a race. The Camino teaches you patience and the importance of enjoying the journey to its fullest.
          <br></br><br></br>Last but not least, there are pilgrims who see the Camino de Santiago as a great cultural adventure; a unique way to delve into the history and traditions of Spain. During the Camino, you'll meet people from all over the world, delve into the local gastronomic culture, and live a unique experience.
          <br></br><br></br><span style={{fontSize: 24}}>P</span>ractical tips for the pilgrimage
          <br></br><br></br>The Camino de Santiago is an experience that will take you through beautiful landscapes, let you discover the rich history of Spain, and create unforgettable encounters. To make the most of this adventure, you need several tips to enjoy the pilgrimage as the pros do. Recommendations that only require a few weeks of adaptation, but once you decide to take the first step on the Camino, you'll appreciate them instantly.
          <br></br><br></br>Physical preparation is possibly one of the most important aspects to take care of before starting the Camino de Santiago. You don't need to be an athlete, but a physical preparation will help you enjoy the journey more. Taking long walks in the weeks leading up to the pilgrimage can be a good way to prepare.
          <br></br><br></br>Choosing the right equipment is crucial. Good hiking boots and knowing what to pack for the Camino de Santiago are two points you should never overlook. In both cases, we're talking about gear that should be comfortable and suitable for all types of weather.
          <br></br><br></br>Planning the route: While you won't have any problems navigating the Camino de Santiago, it never hurts to plan the route to know where to sleep, where to take breaks, etc.
          <br></br><br></br>Take care of your health: During the Camino de Santiago, you won't eat and sleep the same way as on a normal day in your city or town. Try to stay hydrated and, as much as possible, eat nutritious foods. Don't forget about rest, your best ally to perform well in the next stage.
          <br></br><br></br>Embrace the experience: Never forget that the Camino de Santiago is more than just a walk. It's a journey to know yourself, others, new cultures, and traditions. An experience that will change you. So, enjoy it.
          <br></br><br></br>Good journey, pilgrim! 🌍👣
        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip1;