import { useEffect, useState, memo } from "react";
import { Link, } from "react-router-dom";
import { useSelector } from "react-redux";

import { Menu, MenuItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getUser } from "../models/user/userCartSlice";
import { GAEvent } from "../utils/events";

export const MenuSub = memo(function ({ item, closeMenu }) {
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const hasSub = Boolean(item.submenu);
  const user = useSelector(getUser)
  const role = user.role || "basic"
  const url = user.name !== "Guest" && item.user ? `${item.url}/${user._id}`: item.url;

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const ExternalLink = () => {
    return (
      <a href={item.url} 
        onClick={closeMenu}
        target="_blank" 
        rel="noopener noreferrer" 
        style={{
          textDecoration: "none",
          color: "inherit",
      }}>
        <Typography padding={2}>{item.title}</Typography>
      </a>
    )
  }

  const SingleLevel = ({item}) => {
    return (
      role === item.role || item.role === "any" || !item.role ?
      <>
      { item.external ? 
        <ExternalLink />
      :
      <MenuItem
          key={item.title}
          onClick={closeMenu}
          component={Link}
          to={url}
          sx={{ 
            color: 'inherit', 
            display: 'block',
          }}
      >
          {item.title}
      </MenuItem> 
      } 
      </>
      : null
    )
  }

  const MultiLevel = ({item}) => {
    const [anchorElSub, setAnchorElSub] = useState(null);
    const [anchorOrigin, setOrigin] = 
      useState(mobile ? 
      { vertical: 'bottom', horizontal: 'center' } :
      { vertical: 'bottom', horizontal: 'left'});
  
      const handleOpenSubMenu = (event) => {
        GAEvent(event.currentTarget.innerText, "open_sub_menu", "button_press");
        setAnchorElSub(event.currentTarget);
      };
    
      const handleCloseSubMenu = () => {
        setAnchorElSub(null);
        if (closeMenu) closeMenu();
      };

    return (
      role === item.role || item.role === "any" || !item.role ?
      <>
        <MenuItem
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenSubMenu}
        >
          <ListItemText>
            {item.title}
          </ListItemText>
          <ListItemIcon><KeyboardArrowRightIcon/></ListItemIcon>
        </MenuItem>
        <Menu
          anchorEl={anchorElSub}
          anchorOrigin={anchorOrigin}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElSub)}
          onClose={handleCloseSubMenu}
        >          
          {item.submenu.map((subitem) => {
            return (
              role === subitem.role || subitem.role === "any" || !subitem.role ?
                <MenuSub
                  key={subitem.title}
                  item={subitem}
                  closeMenu={handleCloseSubMenu}                 
                />
              : null
            )
          })}
        </Menu>
      </> : null
    )
  };

  return (
    hasSub ? <MultiLevel item={item}/> : <SingleLevel item={item}/>
  )
});

