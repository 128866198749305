export function newCouponObject() {
  const ObjectID = require("bson-objectid");  
  const id = ObjectID().toString();
  return (
      { id,
      code: '',
      name: '', 
      description: '',
      unique: false,
      startDate: new Date(),
      endDate: new Date(),
      cart: false,
      any: false,
      items: [],
      minSelf: false,
      minQty: 0,
      minAmount: 0,
      discountAmount: 0,
      discountPercent: 0,
      discountLimitQty: 0,
      discountLimitAmount: 0,
      isNew: true 
    }
  )
};

export async function updateCoupon(coupon) {  
  const result = await fetch(`/api/coupons/${coupon.id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(coupon)
  });

  return await result.json();
};


export async function copyCoupon(coupon) {
  const ObjectID = require("bson-objectid");  
  const id = ObjectID().toString();
  let newCoupon = structuredClone(coupon);
  newCoupon.id = id;
  newCoupon.isNew = true;
  return newCoupon;
};

export async function deleteCoupon(id) {
  try {
    await fetch(`/api/coupons/delete/${id}`);
  }
  catch(err) {
    console.log(err);
  }
};

export async function getCoupon(id) {
  const result = await fetch(`/api/coupon/${id}`);
  return await result.json();
};
