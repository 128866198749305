import React from 'react';

import { Box, Typography, Paper } from '@mui/material';

const Tip3 = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("../images/question.jpg")'
      }}>
      <Typography sx={{fontWeight: "bold", fontSize: {xs: 20, lg: 24}, pt: 5, pl: 5, pb: 2, pr: 5 }} >
        Packing for the Camino de Santiago
      </Typography>
      <Paper elevation={5} sx={{ width: {xs: "95%", lg: "70%"}, ml: {xs: 1, lg: 5} , mr: {xs: 1, lg: 5}, padding: {xs: 2, lg: 5}, border: 2}}>
        <Typography sx={{fontSize: 16, fontWeight: "bold", color: "black"}} >
          <span style={{fontSize: 24}}>W</span>e know that picking what goes into your backpack is crucial before you even think about hitting the Camino de Santiago trail. So, we've got the lowdown on everything you'll need in this ultimate Camino guide. First things first, know the season you're stepping out because packing for a winter pilgrimage is a whole different ball game compared to strutting your stuff in summer or taking on the Camino in the fall.
          <br></br><br></br>Now, the Camino experts claim they can guess where a pilgrim started just by checking out their backpack. The heavy packers usually kick off from spots closer to Santiago, while those starting from the Pyrenees, knowing they've got over 700 kilometers ahead, carry just what's necessary.
          <br></br><br></br>So, our first suggestion is to pack light but smart. Consider these tips, especially if it's your first time on the Camino.
          <br></br><br></br><span style={{fontSize: 24}}>D</span>ressing for the journey
          <br></br><br></br>Choosing what to wear is all about comfort and adaptability. Remember, less is more on the Camino, and you don't need a closet in your backpack. Go for clothes that wash and dry quick to keep that backpack light. Comfort and practicality – that's your mantra for this adventure. Here's a quick rundown of what to consider:
          <br></br><br></br>Base Clothing
          <ul>
            <li>Tech tees that soak up moisture.</li>
            <li>Easy-breezy pants for smooth moves. If you're into mid-mountain pants, make sure they're broken in. They'll be your daily go-to. Throw in some waterproof pants for the rain, and when the weather's nice, shorts are a must.</li>
          </ul>
          Layers and Defense
          <ul>
            <li>A waterproof jacket and windbreaker are lifesavers. Toss 'em in the backpack when not needed.</li>
            <li>A hat and shades for sun protection, especially on hot days.</li>
            <li>A cozy sweatshirt or sweater for those chillier moments.</li>
          </ul>
          Nighttime Essentials
          <ul>
            <li>Comfy PJs and a warm jacket for the cooler nights.</li>
          </ul>
          Boots or Shoes
          <ul>
            <li>Boots for solid support and terrain protection, or shoes for a lighter feel. Whatever you choose, make sure they're comfy and supportive. And, never skimp on good socks to dodge blisters.</li>
          </ul>
          <span style={{fontSize: 24}}>P</span>ersonal Hygiene and Care on the Camino
          <br></br>Even though you'll be mostly on the move with little time to stop, hygiene and self-care are key. Pack these essentials:
          <br></br><br></br>Eco-Friendly Products
          <ul>
            <li>Keep it green! Opt for biodegradable hygiene products – soap, shampoo, deodorant, toothpaste, the works.</li>
          </ul>
          Towels and Extras
          <ul>
            <li>Quick-dry, light towels are a must. Toss in a toothbrush and other personal care must-haves.</li>
          </ul>
          Sun Shield
          <ul>
            <li>Don't forget sunscreen, especially during the sunnier seasons.</li>
          </ul>
          <span style={{fontSize: 24}}>F</span>irst Aid on the Trail
          <br></br>You guessed it, a first aid kit is a must. You never know what curveballs the Camino might throw at you. Here's what you need:
          <br></br><br></br>Must-Haves in Your First Aid Kit
          <ul>
            <li>Band-aids, bandages, medical tape, scissors, or tweezers. Cover your bases for blisters and cuts.</li>
          </ul>
          Medications
          <ul>
            <li>Paracetamol, ibuprofen, and any prescribed meds should find a spot in your backpack.</li>
          </ul>
          Bug Repellent
          <ul>
            <li>Bugs are part of the Camino deal. So, bug spray is a no-brainer, depending on the season and region.</li>
          </ul>
          <span style={{fontSize: 24}}>H</span>andy Extras for the Journey
          <br></br>Last but not least, some extras that aren't a must but can level up your Camino experience:
          <br></br><br></br>Trekking Poles
          <ul>
            <li>Trekking poles are your buddies on the Camino. Extra support, especially on uneven ground, and a real knee-saver.</li>
          </ul>
Alright, MyCamino3D trailblazer, keep these essentials in mind, and you'll be striding the Camino de Santiago like a pro! 🌟

        </Typography>
      </Paper>    
    </Box>
  );
};

export default Tip3;