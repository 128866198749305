import { Link } from 'react-router-dom';

import { memo } from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import CopyrightIcon from '@mui/icons-material/Copyright';

import { footerItems } from '../models/footerItems';

function FooterMenu() {

  return (
    <>
      {footerItems.map((item, index) => (
        <Grid item key={index} xs={5} sm={2} >
          <Stack spacing={1} >
            <Typography sx={{fontSize: 20, fontWeight: "bold"}}>
              {item.title}
            </Typography>
            {item.submenu.map((sub, index) => (
              <Link
                key={index}       
                to={sub.email ? "#" : sub.url}
                onClick={(e) => {
                  if (sub.email) {window.location.href="mailto:admin@mycamino3d.com";
                  e.preventDefault()};
                }} 
                style={{ textDecoration: "none" }}
              >
                <Typography sx={{fontSize: 20, fontWeight: "bold"}}>
                  {sub.title}
                </Typography>
              </Link>
            ))}
          </Stack>
        </Grid>
      ))}
      <Typography sx={{mt: 4}}>Copyright <CopyrightIcon sx={{fontSize: "small"}}/> 2024 by MyCamino3D</Typography>
    </>  
  )
}

export default memo(FooterMenu);  


