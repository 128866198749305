import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import { AppBar, Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

import './App.css';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { lightTheme } from "./themes/lightTheme";
import { useLoading } from "./components/LoadingContext";
import { AlertDialog } from "./components/AlertDialog";
import { getStatus, getUser, fetchProcessingDate, resolveCartConflict } from "./models/user/userCartSlice";
import { GAEvent } from "./utils/events";
import { Promo } from "./components/Promo";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { pathname, hash, key} = useLocation();
  const location = useLocation();
  const { state} = useNavigation();
  const dialogOpen = Boolean(useSelector(state => state.usercart.cartclash));
  const { loading } = useLoading();
  const dispatch = useDispatch();
  const userStatus = useSelector(getStatus)
  const user = useSelector(getUser)
  const [openPromo, setOpenPromo] = useState(true);
  const { loginWithRedirect } = useAuth0();

  const checkPromoCookie = () => {
    const cookieString = document.cookie;
    return cookieString.includes("promoviewed=true");
  }

  const handleCart = async (event) => {
    const solution = event.target.name  ;
    dispatch(resolveCartConflict(solution));
  }

  const checkCoupon = async(userId, couponCode) => {
    if (userId) {
      const response = await fetch(`/api/orders/${userId}`);
      const invoices = await response.json();
      if (invoices.length > 0) {
        return Boolean(invoices.find(invoice => invoice.coupon?.code.toLowerCase() === couponCode.toLowerCase()));
      } else {
        return false;
      }  
    } else {
      return false;
    }  
  }

  const doLogin = () => {
    GAEvent("login from promotion", "login", "clicked_login");
    loginWithRedirect({appState: { target: '/' }})
  };

  const closePromo = () => {
    GAEvent("promot_viewed", "view_promotion", "clicked_ok_ignore");
    document.cookie = "promoviewed=true";
    setOpenPromo(false);
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    dispatch(fetchProcessingDate());
  }, []);

  useEffect(() => {
    const checkPromo = async () => {
      const usedPromo = await checkCoupon(user._id, "welcome30");
      if (usedPromo) {
        setOpenPromo(false);
      }
    }
    if (user.name !== "Guest") {
      checkPromo();
    }
    if (checkPromoCookie()) {
      setOpenPromo(false);
    }
  }, [user])
  
  return ( 
      <ThemeProvider theme={lightTheme}>
        <CssBaseline/>
          <Box 
            height="100vh" 
            width="100vw"
          >
          <Suspense fallback={<div className="loader-container"></div>}>
            { loading || state !== "idle" || userStatus === "loading" ? <div className="loader-container">Building Model</div> : null }
            <AppBar position="fixed" sx={{ justifyContent: "center", backgroundColor: "white"}}>
              <Promo open={openPromo} setOpen={setOpenPromo} doLogin={doLogin} closePromo={closePromo} />
              <Navbar /> 
            </AppBar>
            <AlertDialog 
              open={dialogOpen} 
              handleClose={event => {
                event.target.name = "open";
                handleCart(event)}} 
              title={"You have a saved cart and an open new cart. Please choose which one to use or if you wish to combine them."}
              buttons={[{ msg: "Saved", action: handleCart, value: "saved" }, 
                        { msg: "Open", action: handleCart, value: "open" },
                        { msg: "Combine", action: handleCart, value: "combine" }
                        ]}
            />
            <CookieConsent
              onAccept={() => GAEvent("Click", "Click Accept Cookies", "Cookie Accept")}
              location="bottom"
              buttonText="I accept"
              cookieName="myAppCookieConsent"
              style={{ background: "#ff9800" }}
              buttonStyle={{ backgroundColor: "yellow", fontSize: "15px" }}
              expires={150}
            >
              <Typography sx={{fontSize: 15, fontWeight: "bold"}}>
                We use cookies to handle logins, purchases and access to outside functions such as Google Maps and Booking.com. If you don't wish to allow the use of cookies on our site, please exit now.</Typography>
            </CookieConsent>
            <Box sx={{height: openPromo ? {xs: 200, md: 160} : 80}}/>
            <Outlet/>
            <Box sx={{height: !openPromo ? {xs: 0, md: 40} : 0}}/>
            <Footer />
            </Suspense>
          </Box>        
      </ThemeProvider>
  );
};

export default App;
