import { Container, Grid, Card, CardActions, CardHeader, CardContent, Button, CardMedia, Typography } from '@mui/material';

const Services = () => {
  return (
    <Container disableGutters maxWidth="100%" sx={{minHeight:"90vh" }}>
      <Typography align="center" padding={2} fontSize={{xs: 25, md: 35}} fontWeight="bold" height={70}  >
        Camino Support Services
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={5} >
          <Card >
            <CardHeader title="Backpack/Luggage Transfer"/>
            <CardMedia
              component="img"
              alt="luggage transfer"
              image="/images/transport.jpg"
              sx={{ height: {xs: "100vw", lg: "30vw"}}}
            />
            <CardContent>
              <Typography fontSize={18}>
                If you know your daily itinerary and you want to loosen your load, there are various companies that will pick up your luggage each morning from your starting location and deliver it by the early afternoon to your destination.  You can book in advance for all or part of your entire journey. <br></br><br></br>Here are links to various services and travel agencies that can arrange this for you. (We have personal experience using the Spanish Post Office, "Correos", and they were very well organized and efficient.)
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained"
                href="https://www.elcaminoconcorreos.com/en/rucksack-transfer"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Correos
              </Button>
              <Button 
                variant="contained"
                href="https://www.jacotrans.es/en/"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                jacotrans
              </Button>
              <Button 
                variant="contained"
                href="https://santiagoways.com/en/transporting-backpacks-on-the-camino-de-santiago/?gad_source=1&gclid=Cj0KCQjwpNuyBhCuARIsANJqL9PJY-lg2TxGfsDMJZnU5zLbAYQK0JqC2N3y-gIk92K_-I420nQ_LTcaAm5cEALw_wcB"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Santiago Ways
              </Button>
              <Button 
                variant="contained"
                href="https://pilbeo.com"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Pilbeo
              </Button>
              <Button 
                variant="contained"
                href="https://caminofacil.net/en/"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                camino fácil
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} >
          <Card >
            <CardHeader title="Luggage Storage in Santiago"/>
            <CardMedia
              component="img"
              alt="luggage storage"
              image="/images/luggage.jpg"
              sx={{ height: {xs: "100vw", lg: "30vw"}}}
            />
            <CardContent>
              <Typography fontSize={18}>
                The post office in Santiago offers a very reasonably priced storage service for luggage, poles or bicycles.   
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained"
                href="https://www.elcaminoconcorreos.com/en/luggage-storage"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} >
          <Card >
            <CardHeader title="Bicycle Transport"/>
            <CardMedia
              component="img"
              alt="bicycle transport"
              image="/images/bicycle.jpg"
              sx={{ height: {xs: "100vw", lg: "30vw"}}}
            />
            <CardContent>
              <Typography fontSize={18}>
                If you are living anywhere in Europe and wish to send your bicycle to and from any stage on the Camino, then Correos and Bicigrino.com offer bicycle transport services. (Bicigrino also offers bike rentals...see below).
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained"
                href="https://www.elcaminoconcorreos.com/en/send-bycicle"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Correos
              </Button>
              <Button 
                variant="contained"
                href="https://bicigrino.bike/en/transporte-de-bicicletas/26-transporte-al-inicio-del-camino.html#"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Bicigrino
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} >
          <Card >
            <CardHeader title="Bicycle Rental"/>
            <CardMedia
              component="img"
              alt="bicycle rental"
              image="/images/camino-biker.jpg"
              sx={{ height: {xs: "100vw", lg: "30vw"}}}
            />
            <CardContent>
              <Typography fontSize={18}>
                About six percent of pilgrims who arrive in Santiago seeking a Compostela have arrived there on a bicycle. Some folks do it because they love bike touring, others do it because they have issues that prevent them from walking, and yet other repeat pilgrims have done a mix of walking and biking. Regardless, cycling the Camino is a legitimate way to engage on the pilgrimage and cyclists are eligible for a Compostela.
                <br></br><br></br>
                There are a number of companies that will rent you a fully prepared bicycle with backpacks, tools, parts, etc., deliver it to your starting location and pick it up at your final stop. Here are some links: 
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained"
                href="https://www.cyclingthecamino.com/en/"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Cycling the Camino
              </Button>
              <Button 
                variant="contained"
                href="https://bicigrino.com/en/"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Bicigrino
              </Button>
              <Button 
                variant="contained"
                href="https://www.bikeiberia.com/camino/bike-rentals-camino/"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Bike Iberia
              </Button>
              <Button 
                variant="contained"
                href="https://followthecamino.com/en/camino-tours/cycling-the-camino-de-santiago/?utm_source=google&utm_medium=cpc&utm_campaign=Leads-Search-CyclingFTC-2024"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Follow the Camino
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} >
          <Card >
            <CardHeader title="Send Your Walking Staff Home"/>
            <CardMedia
              component="img"
              alt="walking staff"
              image="/images/walking_staff.jpg"
              sx={{ height: {xs: "100vw", lg: "30vw"}}}
            />
            <CardContent>
              <Typography fontSize={18}>
                If you purchased an authentic Camino walking staff or poles during your jouney, you may find it difficult to take it back home with you, especially if you are travelling by plane. Correos offers a handy solution.    
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained"
                href="https://www.elcaminoconcorreos.com/en/send-pilgrim-staff"
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{fontSize: {xs: 10, md: 12}}} 
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;

