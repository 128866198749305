import React from 'react';

import { Box, Typography } from '@mui/material';

const Returns = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("/images/camino-de-santiago-en-bici-desde-leon-900x506.jpeg")'
      }}>
      <Typography sx={{pt: 5, pl: 5, pb: 2 }} variant="h4">
      Return and Exchange Policy
      </Typography>
      <Typography sx={{pl: 5, pr: 5, fontSize: 16, fontWeight: "bold", color: "black"}} >
        Hey there! We want you to be totally thrilled with your purchase from MyCamino3D. But we get it, sometimes things don't work out as planned. No worries though, we've got you covered with our hassle-free return and exchange policy.
        <ul>
          <li>Returns:</li>
          <ul>
            <li>If you're not completely satisfied with your purchase, you can return it within 14 days of receiving it for a full refund. Just make sure the item is in its original condition, unused, and in its original packaging. Oh, and don't forget to include the receipt or proof of purchase.</li>
          </ul><br></br>
          <li>Exchanges:</li>
          <ul>
            <li>Need a different size, color, or style? No problemo! You can exchange your item within 30 days of receiving it. Just shoot us an email at admin@mycamino3d with your order number and what you'd like to exchange it for, and we'll get it sorted for you.</li>
          </ul>
          <br></br>
          <li>How to Return or Exchange:</li>
            <ul>
              <li>To start the return or exchange process, simply drop us a line at admin@mycamino3d with your order number and reason for return or exchange. We'll guide you through the process and make sure everything goes smoothly.</li>
            </ul>
           <br></br>
           <li>Exceptions:</li>
           While we strive to make returns and exchanges as easy as pie, there are a few exceptions:
              <ul>
                <li>Custom or personalised items (hey, they're made just for you!)</li>
                <li>Items marked as final sale (we gotta keep the lights on, ya know?)</li>
              </ul>
           <br></br>
           <li>Got Questions?</li>
            <ul>
              <li>If you have any questions or concerns about our return and exchange policy, don't hesitate to reach out. We're here to help! <a  href="mailto:admin@mycamino3d.com">
                Contact us for assistance.</a></li>
            </ul>
        </ul>
        Thanks for shopping with MyCamino3D! We appreciate your support.
      </Typography>
    </Box>
  );
};

export default Returns;

