import React from 'react';

import { Box, Typography } from '@mui/material';

const Privacy = () => {
  return (
    <Box sx={{
      width: '100%', 
      minHeight: "95vh",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover', 
      backgroundImage: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url("/images/camino-de-santiago-en-bici-desde-leon-900x506.jpeg")'
      }}>
      <Typography sx={{pt: 5, pl: 5, pb: 2 }} variant="h4">
        Privacy and Cookie Policy - Last Update: February 1, 2024
      </Typography>
      <Typography sx={{pl: 5, pr: 5, fontSize: 16, fontWeight: "bold", color: "black"}} >
        Thank you for visiting MyCamino3D. This Privacy and Cookie Policy explains how we collect, use, and protect your personal information.
        <ol>
          <li>Information We Collect:</li>
          <ul>
            <li>Personal Information: We may collect your name, email address, and shipping address for order processing.</li>
	          <li>Payment Information: We do not store credit/debit card information. Payments are processed securely through our payment gateway.</li>
	          <li>Cookies: We use cookies to enhance your browsing experience. You can manage cookie preferences in your browser settings.</li>
          </ul><br></br>
          <li>How We Use Your Information:</li>
          <ul>
            <li>Order Processing: We use your personal information to process and fulfill your orders.</li>
            <li>Communication: We may contact you with order updates, promotional offers, or newsletters. You can opt out at any time.</li>
            <li>Analytics: We use analytics tools to analyze website usage, helping us improve our services.</li>
          </ul>
          <br></br>
          <li>Cookie Usage:</li>
            <ul>
              <li>Essential Cookies: Necessary for website functionality.</li>
              <li>Analytics Cookies: Used to analyze website traffic and optimize performance.</li>
              <li>Marketing Cookies: Used for targeted advertising.</li>
            </ul>
           <br></br>
           <li>Third-Party Disclosure:</li>
              <ul>
                <li>We do not sell, trade, or share your personal information with third parties, except for order fulfillment and legal compliance.</li>
              </ul>
           <br></br>
           <li>Your Rights:</li>
            <ul>
              <li>You have the right to access, correct, or delete your personal information. <a  href="mailto:admin@mycamino3d.com">
                Contact us for assistance.</a></li>
            </ul>
            <br></br>
            <li>Security:</li>
            <ul>
              <li>We prioritize the security of your information and use industry-standard measures to protect it.</li>
            </ul>
            <br></br>
            <li>Changes to This Policy:</li>
            <ul>
              <li>We may update this policy periodically. Check the “Last Updated” date for the latest version.</li>
            </ul>
        </ol>
        By using our website, you agree to the terms outlined in this Privacy and Cookie Policy.
        For any questions or concerns, <a  href="mailto:admin@mycamino3d.com">please contact us.</a>
      </Typography>
      <Typography mt={4} fontWeight="bold" align="center">Mycamino3d - Avda de America, 48 - 03560 El Campello - España - Y0022604D</Typography>

    </Box>
  );
};

export default Privacy;