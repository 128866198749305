import { Box } from '@mui/material';
import { useSelector } from "react-redux";
import { getUser } from "../models/user/userCartSlice";

function Account() {
  const user = useSelector(getUser)

  return(
    <Box>
      <h1>Account page</h1>
      <ul>
        <li>
          {user.name}
        </li>
        <li>
          {user.email}
        </li>
      </ul>
    </Box>
    
  )

};

export default Account;
