// Update store from Etsy API
import { useState } from 'react';

import { useLoaderData, useNavigate } from "react-router-dom";

import { DataGrid, GridRowModes, GridActionsCellItem, GridRowEditStopReasons, useGridApiRef } from "@mui/x-data-grid";

import { Button, Box, Grid, IconButton, } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { updateListing, updateProducts, updateProductsFromListing } from '../models/listing';
import SelectCategory from '../components/SelectCategory';
import { useAlertContext } from '../components/AlertContext';
import SelectCollection from '../components/SelectCollection';
import SelectPrices from '../components/SelectPrices';
import SelectProduct from '../components/SelectProduct';
import GlobalPrice from '../components/GlobalPrice';
import { useLoading } from "../components/LoadingContext";

export async function listingLoader() {
  let response = await fetch("/api/listingsall");
  let listings = await response.json();
  for (let di = 0; di < listings.length; di++) {
    listings[di].id = listings[di]._id;
  }
  response = await fetch("/api/store/categories/all");
  const categories = await response.json();
  response = await fetch("/api/store/collections/all");
  const collections = await response.json();
  response = await fetch("/api/convertcurrency/USD");
  const rate = await response.json();
  return { listings, categories, collections, rate };                  
};

// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;

  // const handleClick = () => {
  //   const newObject = newListingObject();
  //   setRows((oldRows) => [...oldRows, newObject]);
  //   setRowModesModel((oldModel) => ({
  //     ...oldModel,
  //     [newObject.id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
  //   }));
  // };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         Add record
//       </Button>
//     </GridToolbarContainer>
//   );
// }

function ListingEdit() {
  const { listings, categories, collections, rate } = useLoaderData();
  const [rows, setRows] = useState(listings);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openCategory, setOpenCategory] = useState(false);
  const [openCollection, setOpenCollection] = useState(false);
  const [openPrices, setOpenPrices] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [openGlobal, setOpenGlobal] = useState(false);
  const [currentListing, setCurrentListing] = useState();
  const apiRef = useGridApiRef();
  const alertContext = useAlertContext();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [priceChanged, setPriceChanged] = useState(listings.map(listing => ({id: listing._id, status: false})));
  
  const handleCategorySelect = (selectedCategories) => {
    let id;
    if (currentListing) {
      id = currentListing.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    apiRef.current.updateRows([{ id: id, categories: selectedCategories }]);
  }

  const handleCollectionSelect = (selectedCollections) => {
    let id;
    if (currentListing) {
      id = currentListing.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    apiRef.current.updateRows([{ id: id, collections: selectedCollections }]);
  }

  const handleProductUpdate = (products) => {
    let id;
    if (currentListing) {
      id = currentListing.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    const adjustedProducts = products.map(product => ({...product, price: Number(product.price)}))
    const price = Math.min(...adjustedProducts.map(product => product.price))
    apiRef.current.updateRows([{ id: id, products: adjustedProducts, price: price }]);
    let newArray = [...priceChanged];
    const index = newArray.findIndex(item => item.id === id);
    newArray[index].status = true; 
    setPriceChanged(newArray);
  }

  const handlePricesUpdate = ({newStyles, newSizes, newColors}) => {
    let id;
    if (currentListing) {
      id = currentListing.id;
    } else {
      const rowCount = apiRef.current.getRowsCount();
      id = apiRef.current.getRowIdFromRowIndex(rowCount-1);
    }
    apiRef.current.updateRows([{ id: id, styles: newStyles }]);
    apiRef.current.updateRows([{ id: id, sizes: newSizes }]);
    apiRef.current.updateRows([{ id: id, colors: newColors }]);

    // adjust product prices based on override prices
    updateProductsFromListing(currentListing);
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // const handleCopyClick = (copyId) => async () => {
  //   let listing = rows.find((listing) => listing.id === copyId);
  //   const copyRow = await copyListing(listing);
  //   setRows((oldRows) => [...oldRows, copyRow]);
  //   setRowModesModel((oldModel) => ({
  //     ...oldModel,
  //     [copyRow.id]: { mode: GridRowModes.Edit, fieldToFocus: 'sourceQty' },
  //   }));
  // };

  // const handleDeleteClick = (id) => async () => {
  //   setRows(rows.filter((row) => row.id !== id));
  //   await deleteDiscount(id);
  // };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  
  const processRowUpdate = async (newRow, oldRow) => {
    var verified = true;
    if (verified) {
      newRow._id = newRow.id;
      let newArray = [...priceChanged];
      const index = newArray.findIndex(item => item.id === newRow.id);  
      if (priceChanged[index].status === true ) {
        await updateProducts(newRow);
        newArray[index].status = false; 
        setPriceChanged(newArray)
      }
      await updateListing(newRow);  
      return newRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const handleCategory = (listing_id) => {
    const isInEditMode = rowModesModel[listing_id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      setCurrentListing(listings.find(listing => listing.id === listing_id));
      setOpenCategory(true);  
    }
  }

  const handleCloseCategory = () => {
    setOpenCategory(false);
  }

  const handleCollection = (listing_id) => {
    const isInEditMode = rowModesModel[listing_id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      setCurrentListing(listings.find(listing => listing.id === listing_id));
      setOpenCollection(true);  
    }
  }

  const handleCloseCollection = () => {
    setOpenCollection(false);
  }

  const handleProduct = (listing_id) => {
    const isInEditMode = rowModesModel[listing_id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      if (listings.find(listing => listing.id === listing_id).products?.length > 0) {
        setCurrentListing(listings.find(listing => listing.id === listing_id));
        setOpenProduct(true);  
      }
    }
  }

  const handleCloseProduct = () => {
    setOpenProduct(false);
  }

  const handlePrices = (listing_id) => {
    const isInEditMode = rowModesModel[listing_id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      setCurrentListing(listings.find(listing => listing.id === listing_id));
      setOpenPrices(true);
    }
  }

  const handleModel = async (listing_id) => {
    // const foundId = listings.find(listing => listing.id === listing_id);
    // console.log(foundId)
    navigate(`/admin/listings/model/${listing_id}`);
  }

  const handleClosePrices = () => {
    setOpenPrices(false);
  }

  const handleOpenGlobal = () => {
    setOpenGlobal(true);
  }

  const handleCloseGlobal = () => {
    setOpenGlobal(false);
  }

  const submitGlobal = async (percent) => {
    handleCloseGlobal();
    setLoading(true);
    const res = await fetch(`/api/products/priceadjust/${percent}`)
    setLoading(false);
    if (res.status === 200) {
      await alertContext.showConfirmation(
        {title: "All product prices successfully updated.",
         cancelButton: "Continue",
        }
      );    
    } else {
      await alertContext.showConfirmation(
        {title: "There was a problem adjusting prices.",
         cancelButton: "Continue",
        }
      );    
    }
    navigate("/admin/listings");
  }
  const columns = [
    {field: 'image', width: 200, renderCell: (params) => 
      <img 
        height="100" 
        src={`${process.env.REACT_APP_SPACES_CDN_ENDPOINT}/listingsimages/${params.row.imageUrls[0]?.thumbnail}`} 
        alt={""}
      />
    },
    { field: 'etsyId', headerName: "Etsy ID", editable: false, width: 100},
    { field: 'price', headerName: "Price", editable: true, width: 100,
      renderCell: (params) => `€${Number(params.value)?.toFixed(2)}`},
    { field: 'state', headerName: "Status", editable: false, width: 100},
    { field: 'etsyTitle', headerName: "Etsy Title", editable: false, width: 300 }, 
    { field: 'shortTitle', headerName: "Short Title", editable: true, width: 150 },
    { field: 'addOn', headerName: "AfterSale", type: "boolean", editable: true, width: 100},
    { field: 'modelUrl', headerName: "Configure Model", width: 100,
        renderCell: (params) => 
          <IconButton 
            style={{color: "inherit"}} 
            onClick={() => handleModel(params.row.id)}>
              <AccountTreeIcon  />
          </IconButton>,
    },
    { field: 'styles', headerName: "Override Prices", width: 100,
      renderCell: (params) => 
        <IconButton 
          style={{color: "inherit"}} 
          onClick={() => handlePrices(params.row.id)}>
            <AccountTreeIcon  />
        </IconButton>,
      },
    { field: 'products', headerName: "Product Prices", width: 100,
    renderCell: (params) => 
      <IconButton 
        style={{color: "inherit"}} 
        onClick={() => handleProduct(params.row.id)}>
          <AccountTreeIcon  />
      </IconButton>,
    },
    { field: 'categories', headerName: "Categories", width: 100,
      renderCell: (params) => 
        <IconButton 
          style={{color: "inherit"}} 
          onClick={() => handleCategory(params.row.id)}>
            <AccountTreeIcon  />
        </IconButton>,
      },
      { field: 'collections', headerName: "Collections", width: 100,
      renderCell: (params) => 
        <IconButton 
          style={{color: "inherit"}} 
          onClick={() => handleCollection(params.row.id)}>
            <AccountTreeIcon  />
        </IconButton>,
      },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]
 
  return(
    <Box >
      <Button sx={{mt: 2, ml: 5}} variant="contained" onClick={handleOpenGlobal}>Adjust all Prices</Button>
      <GlobalPrice 
        open={openGlobal}
        submitGlobal={submitGlobal}
        handleClose={handleCloseGlobal}
      />
      <Grid container sx={{mt:2, ml:4}} height="80vh" alignItems="flex-start" justify="flex-start" direction="column" spacing={2}>  
        <SelectCategory
          categories={categories} 
          open={openCategory} 
          listing={currentListing}
          submitSelect={handleCategorySelect}
          handleClose={handleCloseCategory}
        />  
        <SelectCollection
          collections={collections} 
          open={openCollection} 
          listing={currentListing}
          listings={listings}
          submitSelect={handleCollectionSelect}
          handleClose={handleCloseCollection}
        />  
        <SelectPrices
          open={openPrices} 
          rate={rate}
          listing={currentListing}
          submitChanges={handlePricesUpdate}
          handleClose={handleClosePrices}
        />
        <SelectProduct
          open={openProduct} 
          rate={rate}
          listing={currentListing}
          submitChanges={handleProductUpdate}
          handleClose={handleCloseProduct}
        /> 
        <h1>Listings Maintenance</h1>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          sx={{
            '.MuiDataGrid-columnHeader': {color: "blue"},
            "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          }
        }}
          getRowHeight={() => "auto"}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
        />
      </Grid>
    </Box>
  )

};

export default ListingEdit;
