import { Toolbar, IconButton, Grid, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const Promo = ({open, setOpen, loggedIn, closePromo, doLogin}) => {

  return (
    <>
      {open ?
        <Toolbar disableGutters sx={{cursor: "pointer", height: {xs: 120, md: 80}, backgroundColor: "black"}}>  
          <Grid container direction="row" alignItems="center" justifyContent="center" sx={{padding: {xs: 5, md: 0}}}>
            <Typography textAlign="center" color="white" fontSize={18}>
              {loggedIn ? "Save" : <><span style={{color: "#fdd008"}} onClick={doLogin}>Login to</span><span> save</span></>} <strong>30% off</strong> your first purchase. Use coupon code "welcome30" at checkout.
            </Typography>
            <IconButton sx={{position: "absolute", right: "5px", top: {xs: "40px", md: "17px"}}} aria-label="close" onClick={closePromo}>
              <CloseIcon sx={{fontSize: 30, color: "white"}} />
            </IconButton>
          </Grid>
        </Toolbar>
      : null }
    </>
  )
}

